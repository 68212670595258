
export default {
  "color": [
    "#3aa1ff",
    "#4ecb73",
    "#fbd437",
    "#36cbcb",
    "#f2637b",
    "#975fe5",
    "#fc8452",
    "#ea7ccc",
    "#5254cf"
  ],
  "backgroundColor": "rgba(0,0,0,0)",
  "textStyle": {},
  "title": {
    "textStyle": {
      "color": "#333333"
    },
    "subtextStyle": {
      "color": "#888888"
    }
  },
  "line": {
    "itemStyle": {
      "borderWidth": 1
    },
    "lineStyle": {
      "width": "2"
    },
    "symbolSize": 4,
    "symbol": "emptyCircle",
    "smooth": false
  },
  "radar": {
    "itemStyle": {
      "borderWidth": 1
    },
    "lineStyle": {
      "width": "2"
    },
    "symbolSize": 4,
    "symbol": "emptyCircle",
    "smooth": false
  },
  "bar": {
    "barCategoryGap": "50%",
    "itemStyle": {
      "barBorderWidth": "0",
      "barBorderColor": "#ccc"
    }
  },
  "pie": {
    "itemStyle": {
      "borderWidth": "2",
      "borderColor": "#fff"
    }
  },
  "scatter": {
    "itemStyle": {
      "borderWidth": "0",
      "borderColor": "#ccc"
    }
  },
  "boxplot": {
    "itemStyle": {
      "borderWidth": "0",
      "borderColor": "#ccc"
    }
  },
  "parallel": {
    "itemStyle": {
      "borderWidth": "0",
      "borderColor": "#ccc"
    }
  },
  "sankey": {
    "itemStyle": {
      "borderWidth": "0",
      "borderColor": "#ccc"
    }
  },
  "funnel": {
    "itemStyle": {
      "borderWidth": "0",
      "borderColor": "#ccc"
    }
  },
  "gauge": {
    "itemStyle": {
      "borderWidth": "0",
      "borderColor": "#ccc"
    }
  },
  "candlestick": {
    "itemStyle": {
      "color": "#dd3f5d",
      "color0": "#51bd4b",
      "borderColor": "#dd3f5d",
      "borderColor0": "#51bd4b",
      "borderWidth": 1
    }
  },
  "graph": {
    "itemStyle": {
      "borderWidth": "0",
      "borderColor": "#ccc"
    },
    "lineStyle": {
      "width": 1,
      "color": "#aaa"
    },
    "symbolSize": 4,
    "symbol": "emptyCircle",
    "smooth": false,
    "color": [
      "#3aa1ff",
      "#4ecb73",
      "#fbd437",
      "#36cbcb",
      "#f2637b",
      "#975fe5",
      "#fc8452",
      "#ea7ccc",
      "#5254cf"
    ],
    "label": {
      "color": "#eeeeee"
    }
  },
  "map": {
    "itemStyle": {
      "areaColor": "#fafafa",
      "borderColor": "#d9d9d9",
      "borderWidth": 1
    },
    "label": {
      "color": "#000",
      "textBorderColor": "#fff",
      "textBorderWidth": 1
    }
  },
  "geo": {
    "itemStyle": {
      "areaColor": "#fafafa",
      "borderColor": "#d9d9d9",
      "borderWidth": 1
    },
    "label": {
      "color": "#000",
      "textBorderColor": "#fff",
      "textBorderWidth": 1
    },
    "emphasis": {
      "itemStyle": {
        "areaColor": "rgba(255,215,0,0.8)",
        "borderColor": "#d9d9d9",
        "borderWidth": 1
      }
    }
  },
  "grid": {
    "top": 30,
    "right": 20,
    "left": 60,
    "bottom": 40
  },
  "categoryAxis": {
    "axisLine": {
      "show": true,
      "lineStyle": {
        "color": "#cccccc"
      }
    },
    "axisTick": {
      "show": true,
      "lineStyle": {
        "color": "#cccccc"
      },
      "alignWithLabel": true
    },
    "axisLabel": {
      "show": true,
      "color": "#555555"
    },
    "splitLine": {
      "show": false,
      "lineStyle": {
        "type": "dashed",
        "color": [
          "#E0E6F1"
        ]
      }
    },
    "splitArea": {
      "show": false,
      "areaStyle": {
        "color": [
          "rgba(250,250,250,0.2)",
          "rgba(210,219,238,0.2)"
        ]
      }
    }
  },
  "valueAxis": {
    "axisLine": {
      "show": false,
      "lineStyle": {
        "color": "#cccccc"
      }
    },
    "axisTick": {
      "show": false,
      "lineStyle": {
        "color": "#cccccc"
      }
    },
    "axisLabel": {
      "show": true,
      "color": "#555555"
    },
    "splitLine": {
      "show": true,
      "lineStyle": {
        "type": "dashed",
        "color": [
          "#eeeeee"
        ]
      }
    },
    "splitArea": {
      "show": false,
      "areaStyle": {
        "color": [
          "rgba(250,250,250,0.2)",
          "rgba(210,219,238,0.2)"
        ]
      }
    }
  },
  "logAxis": {
    "axisLine": {
      "show": false,
      "lineStyle": {
        "color": "#cccccc"
      }
    },
    "axisTick": {
      "show": false,
      "lineStyle": {
        "color": "#cccccc"
      }
    },
    "axisLabel": {
      "show": true,
      "color": "#555555"
    },
    "splitLine": {
      "show": true,
      "lineStyle": {
        "color": [
          "#eeeeee"
        ]
      }
    },
    "splitArea": {
      "show": false,
      "areaStyle": {
        "color": [
          "rgba(250,250,250,0.2)",
          "rgba(210,219,238,0.2)"
        ]
      }
    }
  },
  "timeAxis": {
    "axisLine": {
      "show": true,
      "lineStyle": {
        "color": "#cccccc"
      }
    },
    "axisTick": {
      "show": true,
      "lineStyle": {
        "color": "#cccccc"
      }
    },
    "axisLabel": {
      "show": true,
      "color": "#555555"
    },
    "splitLine": {
      "show": false,
      "lineStyle": {
        "color": [
          "#eeeeee"
        ]
      }
    },
    "splitArea": {
      "show": false,
      "areaStyle": {
        "color": [
          "rgba(250,250,250,0.2)",
          "rgba(210,219,238,0.2)"
        ]
      }
    }
  },
  "toolbox": {
    "iconStyle": {
      "borderColor": "#888888"
    },
    "emphasis": {
      "iconStyle": {
        "borderColor": "#888888"
      }
    }
  },
  "legend": {
    "textStyle": {
      "color": "#888888"
    }
  },
  "tooltip": {
    "axisPointer": {
      "lineStyle": {
        "color": "#ccc",
        "width": 1
      },
      "crossStyle": {
        "color": "#ccc",
        "width": 1
      }
    }
  },
  "timeline": {
    "lineStyle": {
      "color": "#DAE1F5",
      "width": 2
    },
    "itemStyle": {
      "color": "#A4B1D7",
      "borderWidth": "1"
    },
    "controlStyle": {
      "color": "#A4B1D7",
      "borderColor": "#A4B1D7",
      "borderWidth": 1
    },
    "checkpointStyle": {
      "color": "#316bf3",
      "borderColor": "fff"
    },
    "label": {
      "color": "#A4B1D7"
    },
    "emphasis": {
      "itemStyle": {
        "color": "#FFF"
      },
      "controlStyle": {
        "color": "#A4B1D7",
        "borderColor": "#A4B1D7",
        "borderWidth": 1
      },
      "label": {
        "color": "#A4B1D7"
      }
    }
  },
  "visualMap": {
    "itemHeight": 80,
    "itemWidth": 15,
    "color": [
      "#bf444c",
      "#d88273",
      "#f6efa6"
    ]
  },
  "dataZoom": {
    "handleSize": "100%"
  },
  "markPoint": {
    "label": {
      "color": "#eeeeee"
    },
    "emphasis": {
      "label": {
        "color": "#eeeeee"
      }
    }
  }
}
