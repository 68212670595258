// 引入axios
import axios from "axios";
import router from "../router";
import {
  Message,Notification
} from "element-ui";
import setting from "./setting";
import store from "@/store";
import {
  MessageBox
} from "element-ui";
import VueI18n from 'vue-i18n'

const request = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000, //请求超时
});

// 请求拦截器
request.interceptors.request.use(
  function (config) {
    const token = setting.takeToken();
    config.headers['language'] = localStorage.getItem('i18n-lang') || 'zn';
    config.headers['language'] = localStorage.getItem('i18n-lang')=='zn'? 'zh':localStorage.getItem('lang');
    if (!config.notNeed && token) {
      config.headers[setting.tokenHeaderName] = token;
    }
    if(config.isBlob==1)config.ResponseType = 'blob'
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 在响应拦截器中添加一个变量来跟踪最后一次弹出的消息
let lastShownMsg = null;
// 在响应拦截器中添加一个变量来跟踪最后一次弹出的消息
let lastShownMsgtwo = null;

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    const res = response.data;
    // 202表示没有token
    // console.log(response,'response')
    if (res.code === 401) {
      localStorage.clear();
      console.log(res.msg,'res.msg')

      // 检查是否与最后一次弹出的消息相同
      if (res.msg !== lastShownMsg) {
        Message({
          message: res.msg || "认证信息已过期，请重新登录",
          type: "error",
        });

       // 更新最后一次弹出的消息
       lastShownMsg = res.msg;
      }

      setTimeout(() => {
        router.push("/login");
        // window.location.reload();
      }, 1500)

      return
    }
    if (res.code === 500) {
      localStorage.setItem('isOver', true);
      // if (res.msg=='内部服务器错误') {
      //   localStorage.clear();
      //   setTimeout(() => {
      //     router.push("/login");
      //     // window.location.reload();
      //   }, 1500)
      // }
      if (res.msg !== lastShownMsgtwo) {
        if(res.msg){
          Message({
            message: res.msg,
            type: "error",
            dangerouslyUseHTMLString: true,
            duration:5000
          });
          lastShownMsgtwo = res.msg;
        }
      }
      return Promise.reject(res);
    }
    if (res.code === 200 || !res.code) {
      localStorage.setItem('isOver', false);
      return res;
    }

  },
  (error) => {
    console.log(error,'error')
    if(error.msg){
      Message({
        message: error.msg,
        type: "error",
      });
    }
    return Promise.reject(error);
  }
);

export default request;
