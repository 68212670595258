<template>
  <div ref="container" @dblclick="fullscreenSwich"
    style="width:100%;height:100%;background-color: #000000;margin:0 auto;" class="video_container">
    <div class="buttons-box" id="buttonsBox">
      <div class="buttons-box-left">
        <i v-if="!playing" class="iconfont icon-play jessibuca-btn" @click="playBtnClick"></i>
        <i v-if="playing" class="iconfont icon-pause jessibuca-btn" @click="pause"></i>
        <i class="iconfont icon-stop jessibuca-btn" @click="destroy"></i>
        <i v-if="isNotMute" class="iconfont icon-audio-high jessibuca-btn" @click="mute()"></i>
        <i v-if="!isNotMute" class="iconfont icon-audio-mute jessibuca-btn" @click="cancelMute()"></i>
      </div>
      <div class="buttons-box-right">
        <span class="jessibuca-btn">{{ kBps }} kb/s</span>
        <!-- <i class="iconfont icon-camera1196054easyiconnet jessibuca-btn" @click="screenshot"></i> -->
        <i class="iconfont icon-shuaxin11 jessibuca-btn" @click="playBtnClick"></i>
        <i v-if="!fullscreen" class="iconfont icon-weibiaoti10 jessibuca-btn" @click="fullscreenSwich"></i>
        <i v-if="fullscreen" class="iconfont icon-weibiaoti11 jessibuca-btn" @click="fullscreenSwich"></i>
      </div>
    </div>
  </div>
</template>

<script>
let jessibucaPlayer = {};
import {
  camera_startTranscode,
  flow_startTranscode
} from "@/api/monitor"
export default {
  name: 'jessibuca',
  data() {
    return {
      playing: false,
      isNotMute: false,
      quieting: false,
      fullscreen: false,
      loaded: false, // mute
      speed: 0,
      performance: "", // 工作情况
      kBps: 0,
      btnDom: null,
      videoInfo: null,
      volume: 1,
      rotate: 0,
      vod: true, // 点播
      forceNoOffscreen: false,
      cameratasker: '',
      videoApiUrl: ''
    };
  },
  props: ['videoUrl', 'error', 'hasAudio', 'height', 'videoId','isPlay'],
  mounted() {
    window.onerror = (msg) => {
      // console.error(msg)
    };
    let paramUrl = decodeURIComponent(this.$route.params.url)
    this.$nextTick(() => {
      this.updatePlayerDomSize()
      window.onresize = () => {
        this.updatePlayerDomSize()
      }
      if (typeof (this.videoUrl) == "undefined") {
        this.videoUrl = paramUrl;
      }
      this.btnDom = document.getElementById("buttonsBox");
      console.log("初始化时的地址为: " + this.videoUrl)
      this.play(this.videoUrl)
      if(this.isPlay)
      this.playBtnClick();
    })
  },
  watch: {
    videoUrl(newData, oldData) {
      this.play(newData)
    },
    videoId(newVal, oldVal) {
      console.log('hello1')
      // 当 videoId 发生变化时停止播放
      if (newVal !== oldVal) {
        console.log('hello2')
        this.destroy().then(() => {
          this.playBtnClick();
        });
      }
    },
    immediate: true
  },
  methods: {
    requestPictureInPicture() {
      let videoDom = this.$refs.container.querySelector('video')
      videoDom.requestPictureInPicture()
      // 开启画中画
    },
    // 根据摄像头获取拉流地址
    getTldz() {

    },
    updatePlayerDomSize() {
      let dom = this.$refs.container;
      let width = dom.parentNode.clientWidth
      let height = (9 / 16) * width

      const clientHeight = Math.min(document.body.clientHeight, document.documentElement.clientHeight)
      if (height > clientHeight) {
        height = clientHeight
        width = (16 / 9) * height
      }

      dom.style.width = width + 'px';
      dom.style.height = height + "px";
    },
    create() {
      let options = {};
      console.log("hasAudio  " + this.hasAudio)

      jessibucaPlayer[this._uid] = new window.JessibucaPro(Object.assign(
        {
          container: this.$refs.container,
          autoWasm: true,
          background: "",
          controlAutoHide: false,
          debug: false,
          decoder: "/js/jessibuca-pro/decoder-pro.js",
          forceNoOffscreen: true,
          hasAudio: typeof (this.hasAudio) == "undefined" ? true : this.hasAudio,
          hasVideo: true,
          heartTimeout: 5,
          heartTimeoutReplay: true,
          heartTimeoutReplayTimes: 3,
          hiddenAutoPause: false,
          hotKey: false,
          isFlv: false,
          isFullResize: false,
          isNotMute: this.isNotMute,
          isResize: false,
          keepScreenOn: false,
          loadingText: "请稍等, 视频加载中......",
          loadingTimeout: 10,
          loadingTimeoutReplay: true,
          loadingTimeoutReplayTimes: 3,
          openWebglAlignment: false,
          operateBtns: {
            fullscreen: false,
            screenshot: false,
            play: false,
            audio: false,
            record: false
          },
          recordType: "webm",
          rotate: 0,
          showBandwidth: false,
          supportDblclickFullscreen: false,
          timeout: 10,
          useMSE: location.hostname !== "localhost" && location.protocol !== "https:",
          useOffscreen: false,
          useWCS: location.hostname === "localhost" || location.protocol === "https",
          useWebFullScreen: false,
          videoBuffer: 0,
          wasmDecodeAudioSyncVideo: true,
          wasmDecodeErrorReplay: true,
          wcsUseVideoRender: true
        },
        options
      ));
      let jessibuca = jessibucaPlayer[this._uid];
      let _this = this;
      jessibuca.on("load", function () {
        console.log("on load init");
      });

      jessibuca.on("log", function (msg) {
        console.log("on log", msg);
      });
      jessibuca.on("record", function (msg) {
        console.log("on record:", msg);
      });
      jessibuca.on("pause", function () {
        _this.playing = false;
      });
      jessibuca.on("play", function () {
        _this.playing = true;
      });
      jessibuca.on("fullscreen", function (msg) {
        console.log("on fullscreen", msg);
        _this.fullscreen = msg
      });

      jessibuca.on("mute", function (msg) {
        console.log("on mute", msg);
        _this.isNotMute = !msg;
      });
      jessibuca.on("audioInfo", function (msg) {
        console.log("audioInfo", msg);
      });

      jessibuca.on("bps", function (bps) {
        // console.log('bps', bps);

      });
      let _ts = 0;
      jessibuca.on("timeUpdate", function (ts) {
        // console.log('timeUpdate,old,new,timestamp', _ts, ts, ts - _ts);
        _ts = ts;
      });

      jessibuca.on("videoInfo", function (info) {
        console.log("videoInfo", info);
      });

      jessibuca.on("error", function (error) {
        console.log("error", error);
      });

      jessibuca.on("timeout", function () {
        console.log("timeout");
      });

      jessibuca.on('start', function () {
        console.log('start');
      })

      jessibuca.on("performance", function (performance) {
        let show = "卡顿";
        if (performance === 2) {
          show = "非常流畅";
        } else if (performance === 1) {
          show = "流畅";
        }
        _this.performance = show;
      });
      jessibuca.on('buffer', function (buffer) {
        // console.log('buffer', buffer);
      })

      jessibuca.on('stats', function (stats) {
        // console.log('stats', stats);
      })

      jessibuca.on('kBps', function (kBps) {
        _this.kBps = Math.round(kBps);
      });

      // 显示时间戳 PTS
      jessibuca.on('videoFrame', function () {

      })

      //
      jessibuca.on('metadata', function () {

      });
    },
    playBtnClick: function (event) {
      if(this.videoId) {
        camera_startTranscode({ id: this.videoId }).then(res => {
          this.cameratasker = res.data.tasker
          this.videoApiUrl = '/srs/rtp/'+res.data.streamId+'.live.flv'
          console.log('this.videoApiUrl',this.videoApiUrl)
          this.play('/srs/rtp/'+res.data.streamId+'.live.flv')
        }).catch(err => {
        })
      }else{
        console.log('this.videoUrl',this.videoUrl)
        this.play(this.videoUrl)
      }

    },
    // 销毁
    goEndTl() {
      let data = {
        tasker: this.cameratasker
      }
      flow_startTranscode(data).then(res => {
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    play: function (url) {
      // 判端进入页面是否初始化了
      if (jessibucaPlayer[this._uid] && !this.videoApiUrl) {
        this.destroy().then(() => {
          jessibucaPlayer[this._uid].on("play", () => {
            this.playing = true;
            this.loaded = true;
          });
          if (jessibucaPlayer[this._uid].hasLoaded()) {
            jessibucaPlayer[this._uid].play(url);
          } else {
            jessibucaPlayer[this._uid].on("load", () => {
              console.log("load 播放")
              jessibucaPlayer[this._uid].play(url);
            });
          }
        });
      } else {
        this.create();
        jessibucaPlayer[this._uid].on("play", () => {
          this.playing = true;
          this.loaded = true;
        });
        if (jessibucaPlayer[this._uid].hasLoaded()) {
          jessibucaPlayer[this._uid].play(url);
        } else {
          jessibucaPlayer[this._uid].on("load", () => {
            console.log("load 播放")
            jessibucaPlayer[this._uid].play(url);
          });
        }
      }
    },
    pause: function () {
      if (jessibucaPlayer[this._uid]) {
        jessibucaPlayer[this._uid].pause();
      }
      this.playing = false;
      this.err = "";
      this.performance = "";
    },
    screenshot: function () {
      if (jessibucaPlayer[this._uid]) {
        jessibucaPlayer[this._uid].screenshot();
      }
    },
    mute: function () {
      if (jessibucaPlayer[this._uid]) {
        jessibucaPlayer[this._uid].mute();
      }
    },
    cancelMute: function () {
      if (jessibucaPlayer[this._uid]) {
        jessibucaPlayer[this._uid].cancelMute();
      }
    },
    destroy: async function () {
      if (jessibucaPlayer[this._uid]) {
        await jessibucaPlayer[this._uid].destroy().then(() => {
          this.create();
        });
      } else {
        this.create();
      }
      if (document.getElementById("buttonsBox") == null) {
        this.$refs.container.appendChild(this.btnDom)
      }
      jessibucaPlayer[this._uid] = null;
      this.playing = false;
      this.err = "";
      this.performance = "";

    },
    eventcallbacK: function (type, message) {
      // console.log("player 事件回调")
      // console.log(type)
      // console.log(message)
    },
    fullscreenSwich: function () {
      let isFull = this.isFullscreen()
      jessibucaPlayer[this._uid].setFullscreen(!isFull)
      this.fullscreen = !isFull;
    },
    isFullscreen: function () {
      return document.fullscreenElement ||
        document.msFullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement || false;
    }
  },
  destroyed() {
    if (jessibucaPlayer[this._uid]) {
      jessibucaPlayer[this._uid].destroy();
    }
    this.playing = false;
    this.loaded = false;
    this.performance = "";
  },
}
</script>

<style>
@import '../css/iconfont.css';

.jessibuca-container {
  height: 100% !important;
}

.buttons-box {
  width: 100%;
  height: 0.2838rem;
  line-height: 0.33538rem;
  background-color: rgba(43, 51, 63, 0.7);
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  bottom: 0;
  user-select: none;
  z-index: 10;
}

.jessibuca-btn {
  width: 20px;
  color: rgb(255, 255, 255);
  line-height: 27px;
  margin: 0px 10px;
  padding: 0px 2px;
  cursor: pointer;
  text-align: center;
  font-size: 24px !important;
}

.buttons-box-right {
  position: absolute;
  right: 0;
}
</style>

<style lang="scss" scoped>
.video_container {
  width: 100%;
  height: 100%;
  position: relative;
}

.inpictrue {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #FFF;
  z-index: 999;
  cursor: pointer;
}
</style>