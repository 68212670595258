/*
 * @Author: liyue liyue@gennlife.com
 * @Date: 2024-07-19 18:02:00
 * @LastEditors: liyue liyue@gennlife.com
 * @LastEditTime: 2024-09-05 11:25:03
 * @FilePath: /genesis-new/Users/liyue/Desktop/liyue/安美/anmei_pc/src/api/monitor.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from "@/config/request";

//监控-获取泵房列表
export function pump_room_list(params) {
    return request({
        url: '/equipment/app/monitor/pumpRoomList',
        method: 'GET',
        params,
    })
}
//监控-泵房抓拍摄像头
export function pumpRoomDefaultCamera(params) {
    return request({
        url: '/equipment/device/pumpRoomDefaultCamera',
        method: 'GET',
        params,
    })
}
//监控-根据设备id开始推流
export function camera_startTranscode(params) {
    return request({
        url: '/equipment/camera/startTranscode',
        method: 'GET',
        params,
    })
}

//监控-根据设备id获取playUrl
export function camera_startTranscodes(params) {
    return request({
        url: '/equipment/device/'+params.id,
        method: 'GET',
    })
}

//监控-根据设备id关闭推流
export function flow_startTranscode(params) {
    return request({
        url: '/media/flow/stopByTasker',
        method: 'GET',
        params,
    })
}

//监控-根据设备id截图
export function camera_catchPic(params) {
    return request({
        url: '/media/camera/catchPic',
        method: 'GET',
        params,
    })
}
//监控-根据设备id 回放
export function camera_startBackTranscode(params) {
    return request({
        url: '/equipment/camera/startBackTranscode',
        method: 'GET',
        params,
    })
}

//下载列表数据
export function downloadList(params) {
    return request({
        url: '/media/camera/downloadList',
        method: 'GET',
        params,
    })
}

//视频加载
export function loadVideo(params) {
    return request({
        url: '/media/camera/downloadVideo',
        method: 'GET',
        params,
    })
}

//视频下载
export function downloadVideo(params) {
    return request({
        url: '/media/camera/downloadToLocal',
        method: 'GET',    
        isBlob:1,   
        params,
    })
}

