<template>
  <div>
    <el-dialog v-dialogDrag :title="$t('i18n_3')" :show-close="false" :close-on-click-modal="false" :visible.sync="visible" @close="visible = false;" width="30%" append-to-body>
      <div slot="title" class="title-content">
        <img v-if="type==1" src="@/assets/images/alarm/imp.jpg" class="title-img" alt="">
        <img v-else src="@/assets/images/alarm/close.jpg" class="title-img" alt="">
        <span class="title-info">{{title}}</span>
      </div>

      <div class="info-tent">
        <div class="info-text" v-html="info"></div>
      </div>
      <div slot="footer" class="dialog-footer text-center">
        <el-button type="danger" @click="visible = false;">{{ $i18n.locale=='en'?'OK':'关闭'}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  props:{
    title:{
      type:String
    },
    type:{
      type:Number
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      info:''
    }
  },
  methods: {
    start(row) {
      this.visible = true
      this.info = row.alarmEventTitle
      console.log(row,'row')
      // getUser({page:1,limit:100}).then(res => {
      //     this.peopleList = res.rows.map((e)=>{
      //         this.$set(e,'text',e.userName)
      //         this.$set(e,'value',e.userId)
      //         return e
      //     })
      // }).finally(() => {
      //     this.loading = false
      // })

    }
  }
}
</script>

<style scoped lang="scss">
/* 定义闪烁动画 */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

::v-deep{
  .el-dialog{
    background: #b02418 !important;
    border-radius: 15px;
    display: flex;
    display: -ms-flex; /* 兼容IE */
    flex-direction: column;
    -ms-flex-direction: column; /* 兼容IE */
    margin:0 !important;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    max-height:calc(100% - 30px);
    max-width:calc(100% - 30px);
  }
  .el-dialog .el-dialog__body{
    max-height: 100%;
    flex: 1;
    -ms-flex: 1 1 auto; /* 兼容IE */
    overflow-y: auto;
    overflow-x: hidden;
  }
  .el-button--danger{
    border-color: #FFF !important;
  }
}

.title-content{
  display: flex;
  align-items: center;
}
.title-img{
  width: 62px;
  height: 62px;
  margin-right: 10px;
}
.title-info{
  font-size: 32px;
  font-weight: 600;
  color: #FFF;
  animation: blink 1s infinite;
}
.info-text{
  font-size: 18px;
  font-weight: 500;
  color: #FFF;
}
.info-tent{
  padding: 0 75px;
}
</style>
