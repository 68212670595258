import Vue from 'vue'
import Element from 'element-ui'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import en from './en.json'
import zn from './zn.json'
export default {
  'zn': {...zn,...zhLocale},
  'en':{...en,...enLocale}, 
}

