<!--  -->
<template>
  <div class="Sidebar">
    <div class="left">
      <div v-if="!isChild">
        <div @click="navClick(item)" :class="['nav-item', pathIndex == index ? 'nav-activation' : '']"
          v-for="(item, index) in navList" :key="index">
          <img :src="pathIndex == index
            ? require(`@/assets/images/layout/${index + 1}s.png`)
            : require(`@/assets/images/layout/${index + 1}.png`)
            " />
          <div style="width: 50px">
            {{ item.name }}
          </div>
        </div>
      </div>

      <div v-if="isChild">
        <div style="display: flex; justify-content: center; margin: 10px">
          <button class="returnBtn" @click="backClick">
            <i class="el-icon-arrow-left"></i>
          {{
            $t("i18n_95")
          }}</button>
        </div>
        <el-menu ref="menu" active-text-color="#bb0100" unique-opened :default-active="activeIndex" class="el-menu-vertical-demo">
          <el-submenu :index="String(index)" v-for="(item, index) in pumpGrouList" :key="index">
            <template slot="title">
              <span>{{ item.label }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item :default-active="'0-' + ind" :index="String(index) + '-' + ind"
                @click="selectHandle(item, ite,String(index) + '-' + ind)" v-for="(ite, ind) in item.children" :key="ind">{{ ite.label + "--" +
                  index + "-" + ind }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </div>
    </div>

    <div class="view" :style="{ padding: !isChild ? '0.10417rem' : '0' }">
      <!-- :style="{  height: 'calc(100vh - ' + h_height + 'px)',padding:!isChild?'0.10417rem':'0' }" -->
      <div class="tab-top">
        <img v-if="isChild && editableTabs.length" src="@/assets/images/layout/qp.png" @click="getFullScreen" alt="">
        <el-tabs v-if="isChild" v-model="editableTabsValue" @tab-click="handleClick" @tab-remove="removeTab">
          <el-tab-pane :closable="index != 0" :key="index" v-for="(item, index) in editableTabs" :label="item.label"
            :name="item.tabName">
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="view-tennt" v-if="isChild">
        <div class="childTitle">{{ openTiele }}</div>
        <div class="view-two">
          <slot></slot>
        </div>
      </div>
      <slot v-if="!isChild"></slot>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { pumpRoomPumpGroup } from "@/api/pumpHouse";
import {
  pumpRoom
} from "@/api/home.js";

export default {
  props: {
    h_height: {
      type: Number,
    },
  },
  data() {
    return {
      pathIndex: 0,
      navList: [
        {
          name: this.$t("i18n_96"),
          path: "/",
        },
        {
          name: this.$t("i18n_97"),
          path: "/pumpHouse",
        },
        {
          name: this.$t("i18n_98"),
          path: "/configuration",
        },
        {
          name: this.$t("i18n_99"),
          path: "/monitor",
        },
        {
          name: this.$t("i18n_100"),
          path: "/alarm",
        },
        {
          name: this.$t("i18n_101"),
          path: "/event",
        },
        {
          name: this.$t("i18n_102"),
          path: "/workOrder",
        },

        {
          name: this.$t("i18n_103"),
          path: "/task",
        },
        {
          name: this.$t("i18n_104"),
          path: "/data",
        },
        {
          name: this.$t("i18n_105"),
          path: "/expert",
        },
      ],
      pumpGrouList: [
        {
          name: this.$t("i18n_106"),
          path: "/stabilizingPump",
        },
        {
          name: this.$t("i18n_107"),
          path: "/electric",
        },
        {
          name: this.$t("i18n_108"),
          path: "/diesel",
        },
        {
          name: this.$t("i18n_109"),
          path: "/endWater",
        },
      ],
      isChild: false,
      pumpRoomId: "",
      editableTabsValue: '0',
      editableTabs: [],
      activeIndex: "0-0",
      titleName: "",
      openTiele: '',
      top_height: 135,
      isScreen: false
    };
  },
  watch: {
    "$route.path"() {
      this.get_navActive();
    },
    $route: {
      immediate: true,
      handler(e) {
        if (this.$route.query.flag && this.$route.query.flag == 2) {
          this.isChild = true;
          this.pumpRoomId = this.$route.query.pumpRoomId;
          this.titleName = this.$route.query.titleName;
          this.pumpRoomPumpGroup();
          this.pumpRoom(); //泵房列表
        } else {
          this.isChild = false;
        }
      },
    },
    pumpRoomId: {
      immediate: true,
      handler(e) {
        if (e) {
          this.editableTabs = []
        }
      },
    },
  },
  created() {
    let tabs = localStorage.getItem('editableTabs');
    if(tabs) this.editableTabs = JSON.parse(tabs)
    this.get_navActive();
    this.get_pumpActive();
  },
  mounted() { },
  methods: {
    pumpRoom() {
      pumpRoom()
        .then((res) => {
          res.data.forEach((e) => {
            if (e.id == this.pumpRoomId) {
              this.openTiele = e.title
            }
          })
        })
        .finally(() => { });
    },
    navClick(item) {
      if (!item.path) return;
      this.$router.push({ path: item.path });
    },
    get_navActive() {
      let index = this.navList.findIndex((v) => v.path == this.$route.path);
      this.pathIndex = index;
    },
    get_pumpActive() {
      let index = this.pumpGrouList.findIndex(
        (v) => v.path == this.$route.path
      );
      console.log(index, "checkindex");
      // this.pathIndex = index;
    },
    backClick() {
      this.$router.push({ path: "/white", query: { flag: 1 } });
      this.$router.push({
        path: "/pumpHouse",
      });
    },
    //泵房-机组tab
    pumpRoomPumpGroup() {
      pumpRoomPumpGroup({ pumpRoomId: this.pumpRoomId })
        .then((res) => {
          let index = 0;
          this.pumpGrouList = res.data.map((e, ind) => {
            if (e.deviceIdentifier =='EndTestingBldg') this.$set(e, "path", "/endWater");
            index = ind;
            if (e.deviceIdentifier =='Jockey')
              this.$set(e, "path", "/stabilizingPump");
            index = ind;
            if (e.deviceIdentifier =='Electric') this.$set(e, "path", "/electric");
            index = ind;
            if (e.deviceIdentifier =='Diesel') this.$set(e, "path", "/diesel");
            index = ind;
            return e;
          });
          setTimeout(() => {
            if (this.$route.path == "/white") {
              let deviceTypeId = this.$route.query.deviceTypeId
              let title = this.$route.query.titleName;
              let str = this.$t("i18n_110");
              let str1 = this.$t("i18n_111");
              let str2 = this.$t("i18n_112");
              let str3 = this.$t("i18n_113");
              console.log(title.indexOf(str3), 'title')
              if (deviceTypeId==8) {
                let item = this.pumpGrouList.find((e) => e.value == deviceTypeId);
                this.selectHandle(item, item.children[0],"2-0");
                this.activeIndex = "2-0";
                return;
              }

              if (deviceTypeId==4) {
                let item = this.pumpGrouList.find((e) =>  e.value == deviceTypeId);
                this.selectHandle(item, item.children[0],"3-0");
                this.activeIndex = "3-0";
                return;
              }

              if (deviceTypeId==6) {
                let item = this.pumpGrouList.find((e) => e.value == deviceTypeId);
                this.selectHandle(item, item.children[0],"0-0");
                this.activeIndex = "0-0";
                return;
              }

              if (deviceTypeId==7) {
                let item = this.pumpGrouList.find((e) =>  e.value == deviceTypeId);
                this.selectHandle(item, item.children[0],"1-0");
                this.activeIndex = "1-0";
                return;
              }

              this.$router.push({
                path: this.pumpGrouList[0].path,
                query: {
                  flag: 2,
                  pumpRoomId: this.pumpRoomId,
                  deviceId: this.pumpGrouList[0].children[0].value,
                  configurationPoster:
                    this.pumpGrouList[0].children[0].configurationPoster,
                },
              });
            }
          }, 100);
          console.log(this.$route, "this.$route.path");
        })
        .finally(() => { });
    },
    handleOpen(e) {
      console.log(e, "open");
    },
    selectHandle(item, ite,ind) {
      console.log(item, ite, 'item ite 123')
      let isOver = false
      this.editableTabs.forEach((e, index) => {
        console.log(ite.label,e.label)
        if (ite.label==e.label) {
          isOver = true
        }
      })
      console.log(isOver,'isOver')
      if (!isOver) {
        let next = parseInt(this.editableTabsValue) + 1 + ''
        let obj = {
          path: item.path,
          label: ite.label,
          tabName: ite.value,
          active:ind,
          query: {
            flag: 2,
            pumpRoomId: this.pumpRoomId,
            deviceId: ite.value,
            configurationPoster: ite.configurationPoster,
          },
        }
        this.editableTabs.push(obj)
        this.editableTabsValue = parseInt(this.editableTabsValue) + 1 + ''
        this.activeIndex = ind
        localStorage.setItem('editableTabs', JSON.stringify(this.editableTabs));
      }
      console.log(this.editableTabs, ' this.editableTabs')
      this.editableTabs.forEach((el, ind) => {
        if (el.label == ite.label) {
          console.log(el, ind, 'el ind')
          this.editableTabsValue = el.tabName
        }
      })

      // return
      this.$router.push({
        path: item.path,
        query: {
          flag: 2,
          pumpRoomId: this.pumpRoomId,
          deviceId: ite.value,
          configurationPoster: ite.configurationPoster,
        },
      });
    },
    handleClick(e) {
      this.editableTabs.forEach((el,ind)=>{
        if(e.index==ind){
          this.activeIndex = el.active
        }
      })
      console.log(this.activeIndex,'this.activeIndex')
      this.$router.push(this.editableTabs[parseInt(e.index)])
      this.$forceUpdate()
    },
    getFullScreen() {
      if (this.isScreen) this.exitFullScreen()
      if (!this.isScreen) this.requestFullScreen()
    },
    requestFullScreen() {
      this.isScreen = true
      let element = document.documentElement;
      let requestMethod = element.requestFullScreen || //W3C
        element.webkitRequestFullScreen || //Chrome
        element.mozRequestFullScreen || //FireFox
        element.msRequestFullScreen; //IE11
      if (requestMethod) {
        requestMethod.call(element);
      } else if (typeof window.ActiveXObject !== "undefined") { //for Internet Explorer
        let wscript = new ActiveXObject("WScript.Shell");
        if (wscript !== null) {
          wscript.SendKeys("{F11}");
        }
      }
    },
    exitFullScreen() {
      this.isScreen = false
      if (document.exitFullScreen) {
        document.exitFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    removeTab(targetName) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      console.log(targetName,'targetName')
      console.log(tabs,'tabs')
      console.log(activeName,'activeName')
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.tabName === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              this.$router.push(nextTab)
              activeName = nextTab.tabName;
              this.activeIndex = nextTab.active
              this.editableTabsValue = activeName;
              this.editableTabs = tabs.filter(tab => tab.tabName !== targetName);
              console.log(nextTab,'nextTab')
            }
          }
        });
        return
      } 
      this.$router.push(this.editableTabs[parseInt(activeName-1)])
      this.activeIndex = parseInt(activeName-1)+'-0'
      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter(tab => tab.tabName !== targetName);
    }
  },
};
</script>
<style scoped lang="scss">
.Sidebar {
  display: flex;
  width: 100%;
  .returnBtn{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    -webkit-transition: .1s;
    -o-transition: .1s;
    transition: .1s;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
    // color: #409EFF;
    // border-color: #c6e2ff;
    // background-color: #ecf5ff;
  }

  .tab-top {
    ::v-deep {
      .el-tabs__header {
        background: #FFF;
        padding: 0 20px 0 80px;
        margin: 0 0 10px;
      }

      .el-tabs__nav-wrap::after {
        background-color: #FFF;
      }

      .el-tabs__item {
        font-weight: 600;
        &:hover {
          color: #BB0100;
        }
      }

      .el-tabs__item.is-active {
        color: #BB0100;
      }

      .el-tabs__active-bar {
        background-color: #BB0100;
      }

      .el-tabs__nav-wrap::after {
        background-color: unset
      }
    }
  }

  .childTitle {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 600;
    color: #333333;
    // line-height: 20px;
    margin-bottom: 10px;
    padding-left: 20px;
  }

  .left {
    // min-width: 177px;
    width: 177px;
    // height: calc(100vh - 105px);
    min-height: calc(100vh - 80px);
    background: #ffffff;
    color: #666666;
    flex: none;

    ::v-deep {
      .el-submenu__title{
        font-size: 13px !important;
        font-weight: 600 !important;
        height: unset;
        line-height: unset;
        padding: 20px;
        white-space: normal;
      }
      .el-menu-item{
        font-size: 12px !important;
        font-weight: 600 !important;
        padding: 10px 10px 0 30px !important;
        height: unset;
        line-height: 24px;
        white-space: normal;
      }
      .el-menu-item-group__title{
        padding: unset !important;
      }
    }

    // overflow-y: auto;
    .nav-item {
      display: flex;
      align-items: center;
      white-space: nowrap;
      font-size: 16px;
      line-height: 25px;
      padding: 17px 0 17px 30px;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
        margin-right: 15px;
      }

      transition: all 0.2s ease-in-out;

      &:hover {
        background: rgba(226, 226, 226, 0.3);
      }
    }

    .nav-activation {
      font-weight: bold;
      color: #bb0100;
      background: #e2e2e2;
    }

    .el-menu {
      border: none;
    }

    .el-submenu .el-menu-item {
      min-width: unset;
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;
    }
  }

  .view {
    background: #f6f6f6;
    // width: 100%;
    // min-width: 1223px;
    height: calc(100vh - 80px);
    // width: calc(100vw - 177px);
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .view-tennt {
    height: calc(100vh - 80px);
    overflow-y: scroll;
  }

  .view-two {
    background: #f6f6f6;
    width: 100%;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    // height: 100%;
  }

  .tab-top {
    position: relative;

    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 24px;
      top: 10px;
      z-index: 9;
      cursor: pointer;
    }
  }
}
</style>
