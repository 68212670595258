<template>
  <div
    class="context-menu"
    :style="{ top: `${menuTop}px`, left: `${menuLeft}px` }"
    @mouseleave="hideMenu"
  >
    <ul class="menu-items">
      <li v-for="item in menuItems" :key="item.id" @click="handleItemClick(item)">
        {{ item.label }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    menuItems: {
      type: Array,
      default: () => [],
    },
    mouseX: {
      type: Number,
      default: 0,
    },
    mouseY: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      menuTop: 0,
      menuLeft: 0,
    };
  },
  watch: {
    mouseX(newVal) {
        this.$nextTick(() => {
            this.menuLeft = newVal + 10; // 增加一个偏移量，将菜单向右偏移
        })
    },
    mouseY(newVal) {
        this.$nextTick(() => {
            this.menuTop = newVal + 10; // 增加一个偏移量，将菜单向下偏移
        })
    },
  },
  methods: {
    handleItemClick(item) {
      this.$emit('menu-item-click', item);
      this.hideMenu();
    },
    hideMenu() {
      this.$emit('menu-hide');
    },
  },
};
</script>

<style scoped>
.context-menu {
  position: fixed; /* 使用 position: fixed 进行定位 */
  z-index: 9999;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 0;
}

.menu-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu-items li {
  padding: 4px 16px;
  cursor: pointer;
}

.menu-items li:hover {
  background-color: #f5f5f5;
}
</style>
