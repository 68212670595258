import request from "@/config/request";

//警告列表
export function getAlarmyep(params) {
    return request({
        url: '/equipment/alarm_record/getAppList',
        method: 'GET',
        params,
    })
}

//弹框告警
export function read(params) {
    return request({
        url: '/equipment/alarm_record/read',
        method: 'GET',
        params,
    })
}

//警告列表
export function getAlarm(params) {
    return request({
        url: '/equipment/alarm_record/getPcList',
        method: 'GET',
        params,
    })
}

//告警类型
export function alarmType(params) {
    return request({
        url: '/equipment/open_api/alarm/type',
        method: 'GET',
        params,
    })
}

//告警级别
export function alarmLevel(params) {
    return request({
        url: '/equipment/open_api/alarm/level',
        method: 'GET',
        params,
    })
}

//告警状态
export function alarmStatus(params) {
    return request({
        url: '/equipment/open_api/alarm/status',
        method: 'GET',
        params,
    })
}

//归档告警
export function fileAlarm(params) {
    return request({
        url: '/equipment/alarm_record/file',
        method: 'GET',
        params,
    })
}

//人员
export function getUser(params) {
    return request({
        url: '/system/user/list',
        method: 'GET',
		params
    })
}

//处理告警
export function dealAlarm(data) {
    return request({
        url: '/equipment/alarm_record/deal',
        method: 'POST',
        data,
    })
}

//警告详情
export function getAlarmRecord(data) {
    return request({
        url: '/equipment/alarm_record/'+data,
        method: 'GET',
		type:'multipart/form-data'
    })
}

//查看工单详情
export function workOrderDetails(data) {
    return request({
        url: '/equipment/maintenance_work_order/getWorkOrderDetails/'+data,
        method: 'GET',
		type:'multipart/form-data'
    })
}

//事件列表
export function getEvent(params) {
    return request({
        url: '/equipment/event_log/getAppList',
        method: 'GET',
        params,
    })
}

//PC-警告-顶部统计
export function getAlarmRecordInfo(params) {
    return request({
        url: '/equipment/alarm_record/getAlarmRecordPcTopInfo',
        method: 'GET',
        params,
    })
}
