<!--  -->
<template>
  <div class="Header">
    <div class="left">
      <img src="@/assets/images/layout/logo3.png" @click="jumpHandle('./')" class="logo" />
      <div class="name">{{ $t('i18n_76') }}</div>
    </div>
    <div class="right">

      <div class="time">
        <img src="@/assets/images/layout/time.png" />
        <div>{{ times[0] }}</div>
        <div style="margin:0 26px;width:70px">{{ times[1] }}</div>
        <div style="margin-right:70px">{{ times[2] }}</div>
      </div>

      <div class="operationList">
        <div v-for="(item, index) in operationList" :key="index">
          <el-badge :value="item.value" class="item" :hidden="index == 2 || item.value == 0">
            <el-tooltip effect="dark" :disabled="index != 2" :content="isOver == false ? $t('i18n_77') : $t('i18n_78')"
              placement="bottom">
              <img :src="item.icon" v-show="item.hidden==1" @click="operationClick(item, index)" :class="{ 'ysss': item.flicker }" />
            </el-tooltip>
          </el-badge>
        </div>
      </div>

      <el-dropdown @command="onUserDropClick" trigger="click">
        <div class="user">
          <img :src="myavatar" />
          <div>{{ loginUser.nickName }}</div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="profile" icon="el-icon-user">
            <span style="white-space: nowrap;">{{ $t('i18n_79') }}</span>
          </el-dropdown-item>
          <el-dropdown-item command="logout" icon="el-icon-switch-button" divided>
            <span style="white-space: nowrap;">{{ $t('i18n_80') }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog v-dialogDrag :title="$t('i18n_81')" :visible.sync="dialogTableVisible">
      <el-table size="mini" :data="gridData">
        <el-table-column property="pumpRoomTitle" :label="$t('i18n_82')" width="200"></el-table-column>
        <el-table-column property="deviceTitle" :label="$t('i18n_83')"></el-table-column>
      </el-table>
    </el-dialog>

    <audio ref="audio" class="aud">
      <source src="https://kyfw.12306.cn/otn/resources/js/framework/audio/message.wav" />
    </audio>
  </div>
</template>

<script>
import { logout, getMyUnreadMessages } from "@/api/user";
import { breathingLightList,getAlarmConfigSet,linkState } from "@/api/common"
import { getAlarmyep, read } from '@/api/alarm.js'
import setting from "@/config/setting";
import { timing } from "@/common/js/mixin";

var dayjs = require("dayjs");
export default {
  mixins: [timing],
  data() {
    return {
      times: [],
      operationList: [
        {
          flicker: true,
          icon: require("@/assets/images/layout/dj.png"),
          value: 0,
          list: [],
          hidden: 0,
        },
        {
          flicker: true,
          icon: require("@/assets/images/layout/lei.png"),
          value: 0,
          list: [],
          hidden: 0,
        },
        {
          flicker: true,
          icon: require("@/assets/images/layout/ljsb.png"),
          hidden: 1,
        },
        {
          flicker: false,
          icon: require("@/assets/images/layout/xx.png"),
          value: this.allRead,
          hidden: 1,
        },
      ],
      weekList: [this.$t('i18n_84'), this.$t('i18n_85'), this.$t('i18n_86'), this.$t('i18n_87'), this.$t('i18n_88'), this.$t('i18n_89'), this.$t('i18n_90')],
      enWeekList: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      dialogTableVisible: false,
      gridData: [],
      isOver: false,
      // path:"ws://anmei.web.xinzhidi.cn:9205/websocket/alarm_record/1",
      path: `${window.location.protocol.includes('https') ? 'wss' : 'ws'}://${window.location.host}/ws/websocket/alarm_record/`,
      socket: "",
      allRead:setting.takeRead()
    };
  },
  computed: {
    // 当前登录用户信息
    loginUser() {
      return this.$store.state.user.user;
    },
    myavatar() {
      let avatar = ''
      let user = this.$store.state.user.user
      if (user.avatar) {
        avatar = user.avatar
      } else {
        if (user.gender == 0) avatar = 'https://me-shop.oss-cn-beijing.aliyuncs.com/2023/07/12/编组@2x_20230712190517A011.png'
        if (user.gender == 1) avatar = 'https://me-shop.oss-cn-beijing.aliyuncs.com/2023/07/12/编组 33@2x_20230712184820A010.png'
      }
      return avatar
    }
  },
  created() {
    this.breathingLightList()
    this.information()
    this.getTime();
    this.dataRefreh()
    setInterval(() => {
      this.getTime();
    }, 1000);
  },
  mounted() {
    // this.init()
  },
  watch:{
    allRead:{
      immediate: true,
      handler(e){
        this.allRead = setting.takeRead()
      }
    }
  },
  methods: {
    dataRefreh(){
      if (this.intervalId != null) {
        return;
      }
      this.intervalId = setInterval(() => {
        linkState().then((res) => {
          this.isOver = res.data
          console.log(this.isOver)
          if (this.isOver == false) {
            this.operationList[2].icon = require("@/assets/images/layout/ljsb.png")
            this.operationList[2].flicker = true
          } else {
            this.operationList[2].icon = require("@/assets/images/layout/lj.png")
            this.operationList[2].flicker = false
          }
        });

        this.breathingLightList()
        this.information()
      }, 5000);
    },
    getTime() {
      this.times = [
        dayjs().format("YYYY.MM.DD"),
        dayjs().format("HH:mm:ss"),
        this.weekList[dayjs().format("d")]
      ];
    },
    jumpHandle(e, flag) {
      if (flag) {
        this.$router.push({ path: e, query: { flag } })
        return
      }
      this.$router.push({ path: e })
    },
    /* 个人信息下拉菜单点击 */
    onUserDropClick(command) {
      if (command === "logout") {
        // 退出登录
        this.$confirm(
          this.$t("i18n_91"),
          this.$t("i18n_92"),
          { type: "warning",confirmButtonText: this.$t('i18n_93'),
          cancelButtonText: this.$t('i18n_94'), }
        )
          .then(() => {
            // 调用接口退出登录
            logout({}).then((res) => {
              console.log("Clearing interval...");
              clearInterval(this.intervalId); //清除计时器
              this.intervalId = null; //设置为null
              setTimeout(() => {
                // 清除缓存的token
                this.$store.dispatch("user/removeToken").then(() => {
                  location.replace("#/login"); // 这样跳转避免再次登录重复注册动态路由
                });
              }, 1000);
            });
          })
          .catch(() => { });
      } else if (command === "profile") {
        if (this.$route.fullPath !== "/user/profile") {
          this.jumpHandle('/user', 0)
        }
      }
    },
    //头部闪烁标列表
    breathingLightList() {
      let db = []
      let cb = []
      breathingLightList().then(res => {
        res.data.forEach(v => {
          if (v.deviceTypeId == 7) {
            db.push({ pumpRoomTitle: v.pumpRoomTitle, deviceTitle: v.deviceTitle })
          }
          if (v.deviceTypeId == 8) {
            cb.push({ pumpRoomTitle: v.pumpRoomTitle, deviceTitle: v.deviceTitle })
          }
        })
        this.operationList[0].value = cb.length
        this.operationList[0].list = cb
        this.operationList[1].value = db.length
        this.operationList[1].list = db

        if (this.operationList[0].value > 0) {
          this.operationList[0].hidden = 1
          this.$forceUpdate()
        }else{
          this.operationList[0].hidden = 0
        }

        if (this.operationList[1].value > 0) {
          this.operationList[1].hidden = 1
          this.$forceUpdate()
        }else{
          this.operationList[1].hidden = 0
        }
      })
    },
    //获取消息未读数
    information() {
      getMyUnreadMessages().then(res => {
        let avg = res.data
        this.$store.dispatch("user/setAllRead",avg).then(() => {
          this.operationList[3].value = this.$store.state.user.allRead
        });
      })
    },
    //顶部点击
    operationClick(item, index) {
      console.log(index, "")
      if (index == 3) {
        this.jumpHandle('/user', 1)
      }

      if (index == 0) {
        console.log(this.operationList[1].list, 'this.operationList[1].list')
        this.gridData = this.operationList[0].list
        this.dialogTableVisible = true
      }

      if (index == 1) {
        this.gridData = this.operationList[1].list
        this.dialogTableVisible = true
      }
    },
    init: function () {
      console.log('init ??')

      if (typeof (WebSocket) === "undefined") {
        alert("您的浏览器不支持socket")
      } else {
        // 实例化socket
        console.log(this.path, 'this.path')
        this.socket = new WebSocket(this.path+this.loginUser.userId+'_pc')
        // 监听socket连接
        this.socket.onopen = this.open
        // 监听socket错误信息
        this.socket.onerror = this.error
        // 监听socket消息
        this.socket.onmessage = this.getMessage
      }
    },
    open: function () {
      console.log("socket连接成功")
    },
    error: function () {
      console.log("连接错误")
    },
    // //获取最新警告弹框
    getAlarmList() {
      getAlarmyep().then(res => {
        let text = ''
        let ids = []
        res.data.forEach((v, k) => {
          if (v.reminderMethod == 2 && !v.isRead) {
            text += v.alarmEventTitle + '\n'
            ids.push(v.id)
          }
        })
        console.log(ids, 'ids')
        if (ids.length > 0) {
          this.$confirm(text, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            read({ ids: ids.toString() }).then(res => { })
          }).catch(() => {
          });
        }
      })
    },
    getMessage: function (res) {
      console.log(res.data, '消息')
      let result = res.data != '来自后台的反馈：连接成功' ? JSON.parse(res.data) : '';
      console.log(result,'result')
      if(!result){
        return 
      }
      let music1 = new Audio(); //建立一个music1对象
      if (result.alarmLevel == 0) {
        music1.src = 'http://downsc.chinaz.net/Files/DownLoad/sound1/201906/11582.mp3'

      }
      if (result.alarmLevel == 1) {
        music1.src = 'http://downsc.chinaz.net/Files/DownLoad/sound1/201906/11582.mp3'
      }
      if (result.alarmLevel == 2) {
        music1.src = 'http://downsc.chinaz.net/Files/DownLoad/sound1/201906/11582.mp3'
      }
      // this.$refs.audio.src = music1;//此处的audio为代码ref="audio"中的audio
      // this.$refs.audio.play();//play()为播放函数

      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.audio = music1;//此处的audio为代码ref="audio"中的audio
          console.log(this.$refs.audio,'this.$refs.audio')
          this.$refs.audio.play();//play()为播放函数
        })
      }, 800);
      // this.$refs.audio.src = music1;//此处的audio为代码ref="audio"中的audio
      // this.$refs.audio.play()

      if (res.data != '来自后台的反馈：连接成功') {
        this.getAlarmList()
      }

    },
    // 发送消息给被连接的服务端
    send: function (params) {
      this.socket.send(params)
    },
    close: function () {
      console.log("socket已经关闭")
    }
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  // .el-dropdown-menu__item {
  //   display: flex;
  //   align-items: center;
  //   justify-content: flex-end;
  // }
}

.Header {
  // min-width: 1400px;
  height: 80px;
  background: linear-gradient(90deg, #cf1818 0%, #bb0100 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 38px 0 10px;

  .right {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;

    .time {
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
    }

    .user {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;

      img {
        width: 42px;
        height: 42px;
        margin: 0 6px;
        border-radius: 50%;
      }
    }

    .operationList {
      display: flex;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }

      .item {
        margin-right: 24px;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;

    .logo {
      // width: 163px;
      height: 38px;
      margin-right: 50px;
      cursor: pointer;
      img{
        object-fit: cover;
      }
    }

    .name {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
}

.ysss {
  animation: huxi 1200ms ease-out infinite alternate;
}

@keyframes huxi {
  0% {
    opacity: .6;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
