<template>
  <div class="layout">
    <Header class="Header" ref="head" />

    <Sidebar :h_height="h_height">
      <router-view class="router-view" :key="$route.query.deviceId" />
    </Sidebar>

  </div>
</template>

<script>
import Header from "@/layout/Header.vue";
import Sidebar from "@/layout/Sidebar.vue";
import setting from '@/config/setting';
import {me} from "@/api/user"

export default {
  components: {
    Header,
    Sidebar,
  },
  data() {
    return {
      // 全局配置
      setting: setting,
      h_height:50
    };
  },
  created() {
    let lang = localStorage.getItem('lang');
    this.$i18n.locale = lang?lang:'zn'
    // 获取用户信息
    this.getUserInfo();
  },
  mounted(){
    this.h_height = this.$refs.head.$el.offsetHeight
    console.log(this.h_height,'this.h_height')
  },
  computed: {},
  methods: {
    /* 获取当前用户信息 */
    getUserInfo() {
      me({}).then(({data}) => {
        this.$store.dispatch('user/setUser', data);
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.layout{

}
</style>
