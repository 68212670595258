<template>
  <div class="container h100" :key="$route.fullPath" @click="hideContextMenu">
    <el-row class="h100">
      <el-col :span="6">
        <div class="grid-content bg-purple h100">
          <div class="first_col">
            <div class="status box" style="display: flex;flex-direction: column;">
              <div class="box-title" style="flex:0.2">
                <div class="title">{{ $t("i18n_738") }}</div>
                <div style="font-size: 12px">
                  {{ $t("i18n_739") }}：{{ pumpRoomList.length }}
                </div>
              </div>
              <el-carousel
                style="flex:0.8"
                indicator-position="none"
                :autoplay="false"
                :loop="false"
                trigger="click"
                height="150px"
              >
                <el-carousel-item v-for="ind in swiperList" :key="ind">
                  <div class="chart-container" style="justify-content: center;margin-top: 0;">
                    <div
                      @dblclick="
                        handleMenuItemClick({ id: 14, pumpRoomId: item.id })
                      "
                      @contextmenu.prevent="showContextMenu('element7', item)"
                      v-for="(item, index) in ind"
                      :key="index"
                      id="status_echart1"
                    >
                      <EleChart
                        class="healthyChart"
                        :option="chartOption(item)"
                      ></EleChart>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.title"
                        :disabled="item.title.length < 10"
                        placement="bottom"
                      >
                        <div class="chart-title">{{ item.title }}</div>
                      </el-tooltip>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
              <!-- <div class="chart-container">
                <div
                  @dblclick="
                    handleMenuItemClick({ id: 14, pumpRoomId: item.id })
                  "
                  @contextmenu.prevent="showContextMenu('element7', item)"
                  id="status_echart1"
                  v-for="(item, index) in pumpRoomList"
                  :key="index"
                >
                  <EleChart
                    style="height: 120px"
                    :option="chartOption(item)"
                  ></EleChart>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.title"
                    :disabled="item.title.length < 10"
                    placement="bottom"
                  >
                    <div class="chart-title">{{ item.title }}</div>
                  </el-tooltip>
                </div>
              </div> -->
            </div>
            <div class="count boxdownelse" style="overflow-y: unset;">
              <div class="title totalTitle">{{ $t("i18n_740") }}</div>
              <div class="counttop" style="flex: 1;">
                <div
                  class="countbox1"
                  @dblclick="jumpHandle('./configuration')"
                  @contextmenu.prevent="showContextMenu('element1')"
                >
                  <EleChart
                    style="width: 100%; height: 100%"
                    :option="cakeOption"
                  ></EleChart>
                </div>
              </div>
              <div
                  class="chart-container"
                  style="height: 30%; overflow: unset;flex: 1;"
                >
                  <div
                    @contextmenu.prevent="showContextMenu('element1')"
                    id="status_echart1"
                  >
                    <EleChart
                      style="height: 100%"
                      :option="oracleOption"
                    ></EleChart>
                  </div>
                  <div
                    @contextmenu.prevent="showContextMenu('element1')"
                    id="status_echart1"
                  >
                    <EleChart
                      style="height: 100%"
                      :option="oracleOption2"
                    ></EleChart>
                  </div>
                </div>
              <div
                class="chart-container chartline"
                style="flex: 1;"
                @contextmenu.prevent="showContextMenu('element1')"
              >
                <EleChart
                  style="height: 100%; width: 100%"
                  :option="allLineOption(item)"
                ></EleChart>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple-light h100 second_col">
          <div
            class="map box"
            style="padding: 0"
            @contextmenu.prevent="showContextMenu('element2')"
          >
            <Map ref="map" />
          </div>
          <div class="warn_e boxdownelse">
            <div class="title">{{ $t("i18n_741") }}</div>
            <div class="info-content" >
              <!-- <el-carousel class="lunbo" height="25" direction="vertical" v-if="noticeInfo.length">
                <el-carousel-item  class="info" v-for="(ite, ind) in noticeInfo" :key="ind"> -->
                <vue-seamless-scroll
                  v-if="noticeInfo.length"
                  style="overflow: hidden"
                  :data="noticeInfo"
                  :class-option="defaultOption"
                >
                <div
                  class="info"
                  v-for="(item, index) in noticeInfo"
                  :key="index"
                  @dblclick="jumpEventAlarm(item)"
                  @contextmenu.prevent="
                    showContextMenu('element3');
                    infoType = item.type;
                  "
                >
                  <span
                    :style="{
                      color: item.type == 1? '#BB0100' : '#2ECF6B',
                    }"
                    >【{{ item.type==1? $t('i18n_742'): $t('i18n_743') }}】</span
                  >{{ item.createTime + " " + item.title + " "+ item.deviceTitle }}
                </div>
                </vue-seamless-scroll>
                <!-- </el-carousel-item>
              </el-carousel> -->
              <el-empty
                v-if="noticeInfo.length < 1"
                :description="$t('i18n_744')"
              ></el-empty>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple-light h100 third_col">
          <div
            class="warn_e_count boxelse"
            @dblclick="jumpHandle('./alarm')"
            @contextmenu.prevent="showContextMenu('element4')"
          >
            <div class="title">{{ $t("i18n_745") }}</div>
            <EleChart
              style="height: calc(100% - 30px)"
              :option="lineOption"
            ></EleChart>
          </div>
          <div
            class="progress boxelse"
            @dblclick="jumpHandle('./task')"
            @contextmenu.prevent="showContextMenu('element5')"
            v-loading="loading"
          >
            <div
              class="title"
              style="
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              {{ $t("i18n_746") }}
              <el-select
                style="width: 100px"
                size="mini"
                clearable
                @change="pumpRoomChange"
                v-model="where.pumpRoomId"
                :placeholder="$t('i18n_747')"
              >
                <el-option
                  v-for="item in pumpRoomList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                height: calc(100% - 50px);
                justify-content: space-between;
              "
            >
              <div v-for="(item, index) in progress" :key="index">
                <el-row style="display: flex; align-items: center">
                  <el-col :span="6"
                    ><span class="progress-title">{{ item.name
                    }}</span></el-col
                  >
                  <el-col :span="18"
                    ><el-progress
                      color="#26D456"
                      :percentage="item.percentage * 100"
                      :format="(event) => format(event, item)"
                    ></el-progress
                  ></el-col>
                </el-row>
              </div>
            </div>
          </div>
          <div
            class="video boxdown"
            @dblclick="jumpHandle('./monitor')"
          >
            <div class="title">{{ $t("i18n_748") }}</div>
            <!-- <div class="video-content">
              <div class="player">
                <div ref="videoOuter" class="video-player-page">
                </div>
                <div v-if="cameraList.length<1">
                  <el-empty :description="$t('i18n_749')"></el-empty>
                </div>
              </div>
              
            </div> -->
            <div class="video-content" v-if="yepList.length">
              <el-carousel
                indicator-position="none"
                :autoplay="false"
                :loop="false"
                trigger="click"
                height="100%"
                @change="carouselChange"
              >
                <el-carousel-item v-for="(item,index) in yepList" :key="index">
                    <div class="player" @contextmenu.prevent="showContextMenu('element6')">
                      <transition name="el-fade-in-linear">
                      <!-- <cviewplayer
                        v-if="!isPlay"
                        class="cviewplayer"
                        :videoUrl="item.videoUrl"
                        :index="index"
                        :isPlay="isPlay"
                        :id="item.id"
                        @PlayClose="PlayClose"
                        @getCameratasker="getCameratasker"
                        :ref="`cviewplayer_${index}`"
                      ></cviewplayer> -->
                      <!-- <aliplayer v-if="active == index" :ref="`cviewplayer_${index}`" :videoId="item.id" :videoUrl="item.videoUrl" :isLive="true"></aliplayer> -->
                      <player :ref="`cviewplayer_${index}`" :videoId="item.id" fluent autoplay @destroy="destroy" class="player-wrap" />
                      </transition>
                    </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="video-empty" v-else>
              <el-empty :description="$t('i18n_750')"></el-empty>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 引入 ContextMenu 组件 -->
    <ContextMenu
      v-if="isContextMenuVisible"
      :menu-items="currentMenuItems"
      :mouseX="mouseX"
      :mouseY="mouseY"
      @menu-item-click="handleMenuItemClick"
      @menu-hide="hideContextMenu"
    />
  </div>
</template>

<script>
import EleChart from "@/components/ele-chart";
import * as echarts from "echarts";
import Map from "@/components/map";
import ContextMenu from "@/components/ContextMenu";
import setting from "@/config/setting";
import { timing } from "@/common/js/mixin";
import vueSeamlessScroll from 'vue-seamless-scroll/src';
import aliplayer from '@/views/components/player/aliplayer.vue';
import player from '@/views/components/player/jessibuca.vue';
import axios from "axios";

import {
  pumpRoom,
  pumpRoomEnvironmentData,
  healthStatusDetails,
  deviceStatistical,
  realTimeAlarmOrEvent,
  alarmStatistical,
  inspectionProgressVo,
  mapCoordinate,
} from "@/api/home.js";
import {
		pump_room_list,
		camera_startTranscode,
		flow_startTranscode,
		camera_catchPic,
	} from "@/api/monitor"
//这个是引用子组件
import cviewplayer from '@/components/cview-player'

const datalist = [780, 600, 770, 770, 770];
const namelist = ["水(m³)", "电(Kwh)", "蒸汽(t)", "天然气(m³)", "压缩空气(m³)"];
export default {
  components: {
    EleChart,
    Map,
    ContextMenu,
    cviewplayer,
    vueSeamlessScroll,
    aliplayer,
    player
  },
  mixins: [timing],
  computed: {
    oracleOption() {
      const option = {
        color: ["#EF9D11", "#EFEFEF"],
        title: {
          show: true,
          text: this.oracleInfo.maintenanceNum,
          subtext: this.$t("i18n_751"),
          x: "center",
          y: "center",
          textStyle: {
            fontSize: "15",
            color: "#333333",
            fontWeight: "bold",
          },
          subtextStyle: {
            fontSize: "10",
            color: "#999999",
            fontWeight: "normal",
            bottom: "30%",
          },
        },
        // 提示框不显示
        tooltip: {
          trigger: "item",
          formatter: "{e}%",
          show: false,
        },
        legend: {
          textStyle: {
            fontSize: 15,
            color: "rgba(255,255,255)",
          },
        },
        series: {
          name: "",
          type: "pie",
          radius: ["50%", "70%"],
          center: ["50%", "60%"],
          avoidLabelOverLap: true,
          hoverAnimation: false,
          // 设置标签不显示
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: false,
            },
          },
          // 设置标签线条不显示
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: [
            {
              value: parseInt(
                (this.oracleInfo.maintenanceNum / this.oracleInfo.total) * 100
              ),
              name: "",
              color: "red",
              itemStyle: {
                borderRadius: [10, 10, 10, 10],
              },
            },
            {
              value:
                100 -
                parseInt(
                  (this.oracleInfo.maintenanceNum / this.oracleInfo.total) * 100
                ),
              name: "",
              itemStyle: {
                borderRadius: [10, 10, 10, 10],
              },
            },
          ],
        },
      };
      return option;
    },
    oracleOption2() {
      const option = {
        color: ["#38C25D", "#EFEFEF"],
        title: {
          show: true,
          text: this.oracleInfo.normalNum,
          subtext: this.$t("i18n_752"),
          x: "center",
          y: "center",
          textStyle: {
            fontSize: "15",
            color: "#333333",
            fontWeight: "bold",
          },
          subtextStyle: {
            fontSize: "10",
            color: "#999999",
            fontWeight: "normal",
            bottom: "30%",
          },
        },
        // 提示框不显示
        tooltip: {
          trigger: "item",
          formatter: "{e}%",
          show: false,
        },
        legend: {
          textStyle: {
            fontSize: 15,
            color: "rgba(255,255,255)",
          },
        },
        series: {
          name: "",
          type: "pie",
          radius: ["50%", "70%"],
          center: ["50%", "60%"],
          avoidLabelOverLap: true,
          hoverAnimation: false,
          // 设置标签不显示
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: false,
            },
          },
          // 设置标签线条不显示
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: [
            {
              value: parseInt(
                (this.oracleInfo.normalNum / this.oracleInfo.total) * 100
              ),
              name: "",
              color: "red",
              itemStyle: {
                borderRadius: [10, 10, 10, 10],
              },
            },
            {
              value:
                100 -
                parseInt(
                  (this.oracleInfo.normalNum / this.oracleInfo.total) * 100
                ),
              name: "",
              itemStyle: {
                borderRadius: [10, 10, 10, 10],
              },
            },
          ],
        },
      };
      return option;
    },
    cakeOption() {
      //当前视口宽度
      let nowClientWidth = document.documentElement.clientWidth;
      // 换算方法
      function nowSize(val, initWidth = 1920) {
          return val * (nowClientWidth / initWidth);
      };
      const newArr = JSON.parse(JSON.stringify(this.deviceInfo));
      const option = {
        tooltip: {
          trigger: "item",
          position: 'top',
          formatter: (v) => v.data.name
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            data: newArr.map((e) => {
              e.name = e.name + "：" + e.value;
              e.label = {fontSize:nowSize(12)}
              return e;
            }),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      return option;
    },
    lineOption() {
      const option = {
        xAxis: {
          type: "category",
          data: this.alarmStatisticalInfo.date,
        },
        yAxis: {
          type: "value"
        },
        tooltip: {
          trigger: 'axis'
        },
        // dataZoom: [
        //   {
        //     startValue: "",
        //     height: 10,
        //     top: "92%",
        //   }
        // ],
        legend: {
          data: this.seriesNameList,
          left: "top",
          align: "auto",
          type: "scroll",
        },
        series: this.alarmStatisticalInfo.series,
      };
      return option;
    },
    twigOption() {
      const option = {
        grid: {
          top: "0",
          left: "1%",
          right: "10%",
          bottom: "0",
        },
        xAxis: {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: [
          {
            data: ["水", "电", "蒸汽", "天然气", "压缩空气"],
            inverse: true,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              color: "#fff",
              fontSize: 18,
            },
          },
          {
            type: "category",
            axisTick: { show: false }, // 不显示刻度线
            axisLabel: {
              color: "#000",
            },
            axisLine: {
              show: false,
            },
            inverse: true,
          },
        ],
        series: [
          {
            name: this.$t("i18n_753"),
            yAxisIndex: 0,
            type: "pictorialBar",
            symbol: "rect", // 长方形
            symbolRepeat: "fixed",
            symbolMargin: "2", // 缝隙
            symbolClip: true,
            symbolSize: ["3", "16"],
            barCategoryGap: 5,
            label: {
              show: true,
              position: [0, 50],
              fontSize: 14,
              formatter: (name) => namelist[name.dataIndex],
            },
            zlevel: 1, //  zlevel 大的 Canvas 会放在 zlevel 小的 Canvas 的上面
            data: [
              {
                value: 780,
                itemStyle: {
                  normal: {
                    color: "#047CFF",
                  },
                },
              },
              {
                value: 600,
                itemStyle: {
                  normal: {
                    color: "#EE2800",
                  },
                },
              },
              {
                value: 770,
                itemStyle: {
                  normal: {
                    color: "#FFAD27",
                  },
                },
              },
              {
                value: 770,
                itemStyle: {
                  normal: {
                    color: "#42CEFF",
                  },
                },
              },
              {
                value: 770,
                itemStyle: {
                  normal: {
                    color: "#BF0AFF",
                  },
                },
              },
            ],
          },
          {
            name: this.$t("i18n_754"),
            type: "bar",
            yAxisIndex: 1,
            barGap: "-100%", // 不同系列的柱间距离，为百分比。
            barCategoryGap: 5,
            barWidth: 20,
            left: "50",
            data: [1000, 1000, 1000, 1000, 1000],
            color: "rgba(152, 144, 238, 0.12)", // 柱条颜色
            itemStyle: {
              normal: {
                color: function (params) {
                  var colorList = [
                    "rgba(4,124,255,0.4)",
                    "rgba(242,104,0,0.4)",
                    "rgb(255,224,117,0.4)",
                    "	rgb(79,163,255,0.4)",
                    "rgb(191,10,255,0.4)",
                  ];
                  return colorList[params.dataIndex];
                },
                barBorderRadius: [10], //设置圆角
              },
            },
            label: {
              normal: {
                show: true, // 是否显示进度条上方的百分比
                formatter: (series) => datalist[series.dataIndex] + "/1000",
                position: [100, -20],
                textStyle: {
                  fontSize: 14,
                },
              },
            },
          },
        ],
      };
      return option;
    },
  },
  data() {
    return {
      active: 0,
      chartDataList: [],
      info: [],
      isContextMenuVisible: false, // 右键菜单是否可见
      mouseX: 0, // 鼠标 X 坐标
      mouseY: 0, // 鼠标 Y 坐标
      menuItems: {
        element1: [
          { id: 1, label: this.$t("i18n_755") },
          { id: 2, label: this.$t("i18n_756") },
        ],
        element2: [
          { id: 3, label: this.$t("i18n_757") },
          { id: 4, label: this.$t("i18n_758") },
          { id: 5, label: this.$t("i18n_759") },
        ],
        element3: [
          { id: 6, label: this.$t("i18n_760") },
          { id: 7, label: this.$t("i18n_761") },
        ],
        element4: [
          { id: 8, label: this.$t("i18n_762") },
          { id: 9, label: this.$t("i18n_763") },
        ],
        element5: [
          { id: 10, label: this.$t("i18n_764") },
          { id: 11, label: this.$t("i18n_765") },
        ],
        element6: [
          { id: 12, label: this.$t("i18n_766") },
          { id: 13, label: this.$t("i18n_767") },
        ],
        element7: [
          { id: 14, label: this.$t("i18n_768") },
          { id: 15, label: this.$t("i18n_769") },
        ],
      },
      currentElement: null, // 当前右键点击的元素
      currentMenuItems: [], // 当前右键菜单项数组
      progress: [
        {
          enName: "Weekly",
          name: this.$t("i18n_770"),
          value: "50",
          total: "100",
          percentage: "50%",
        },
        {
          enName: "Monthly",
          name: this.$t("i18n_771"),
          value: "50",
          total: "100",
          percentage: "50%",
        },
        {
          enName: "Quarterly",
          name: this.$t("i18n_772"),
          value: "50",
          total: "100",
          percentage: "50%",
        },
        {
          enName: "Annual",
          name: this.$t("i18n_773"),
          value: "50",
          total: "100",
          percentage: "50%",
        },
      ],
      deviceInfo: [],
      oracleInfo: {},
      alarmStatisticalInfo: {},
      pumpRoomList: [],
      where: {},
      loading: false,
      infoLoading: false,
      seriesNameList: [],
      infoType: "",
      pumpRoomId: "",
      swiperList: [],
      cameraList:[],
      cameratasker:[],
      cameraXzId:'',
      isPlay:false,
      newCameraList:[],
      noticeInfo:[],
      defaultOption: {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
      yepList:[]
    };
  },
  created() {
    // 在父组件的 created 生命周期钩子中添加全局点击事件监听器
    window.addEventListener("click", this.hideContextMenu);
    window.addEventListener('beforeunload', this.handleBeforeUnload, false);
    window.addEventListener('unload', this.handleBeforeUnload, false);
  },
  beforeDestroy() {
    // 在父组件的 beforeDestroy 生命周期钩子中移除全局点击事件监听器
    window.removeEventListener("click", this.hideContextMenu);
    window.removeEventListener('beforeunload', this.handleBeforeUnload, false);
    window.removeEventListener('unload', this.handleBeforeUnload, false);
  },
  mounted() {
    this.queryAll();
  },
  methods: {
    destroy(idx) {
      console.log(idx);
      // this.clear(idx.substring(idx.length - 1));
    },
    getCameratasker(e){
      this.cameratasker.push(e)
      this.cameratasker = [...new Set(this.cameratasker)]
    },
    carouselChange(index,oindex){
      this.active = index
      let newArr = this.sortByOtherArray(this.newCameraList,this.cameraList)
      this.yepList = newArr
      this.$refs['cviewplayer_'+oindex][0].goEndTl()
      this.$refs['cviewplayer_'+oindex][0].destroy()
    },
    async handleBeforeUnload(e) {
        for (const el of this.cameratasker) {
          fetch('http://localhost:9000/media/flow/stopByTasker?tasker='+el, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              'Authorization': setting.takeToken()
            },
            // 保持连接
            keepalive: true,
          });
        }
    },
    // 结束推流
    async goEndTl(ea) {
      let data = {
        tasker: ea.cameratasker
      }
      const res = await flow_startTranscode(data)
    },
    sortByOtherArray(arr1, arr2) {
      return arr2.map(id => arr1.find(item => item.id === id));
    },
    //接收子级传来的是否播放状态
    PlayClose() {
      this.isPlay = false
    },
    // 定时刷新
    dataRefreh() {
      if (this.intervalId != null) {
        return;
      }
      this.intervalId = setInterval(() => {
        realTimeAlarmOrEvent()
          .then((res) => {
              if(res.data[0].id==this.noticeInfo[0].id){
                return
              }
              this.noticeInfo = res.data;
          })
          .finally(() => {
          });
       this.pumpRoom(1) 

        let data = {
          pumpRoomId: this.where.pumpRoomId,
        };
        inspectionProgressVo(data)
          .then((res) => {
            const result = this.compareArraysByProperty(this.progress,res.data,'value')
            if(result){
              return false
            } 
            this.progress = res.data
          })
      }, 5000);
    },
    pumpRoomChange() {
      this.newCameraList = []
      this.$store.dispatch("user/setPumpId", this.where.pumpRoomId);
      this.inspectionProgressVo();

      if(this.pumpRoomList.length){
        this.pumpRoomList.forEach((e) => {
          if (e.id == this.where.pumpRoomId) {
            this.cameraList = e.cameraList.map(item=>item.id)
            this.cameraXzId = this.cameraList
            this.newCameraList = e.cameraList
            this.cameraList.forEach((el,index)=>{
              // this.getTldz(el,index)
            })
          }
        });
      }
    },
    jumpHandle(e, query) {
      if (query) {
        this.$router.push({ path: e, query });
      } else {
        this.$router.push({ path: e });
      }
    },
    //请求all
    queryAll() {
      this.deviceStatistical(); //首页-设备统计
      this.realTimeAlarmOrEvent(); //首页-实时告警、事件
      this.alarmStatistical(); //首页-告警统计
      this.pumpRoom(); //泵房列表
      this.mapCoordinate(); //地图
      this.dataRefreh(); //定时刷新
    },
    // 根据摄像头获取拉流地址
    getTldz(e,index) {
      let data = {
        id: e
      }
      camera_startTranscode(data).then(res => {
        console.log(res, "获取到的拉流地址")
        let obj = {
          cameratasker:res.data.tasker,
          id: e,
          videoUrl:'/srs'+res.data.urlName +'.flv',
        }
        this.newCameraList.push(obj)
        this.isPlay = true
      }).catch(err => {
      })
    },
    showContextMenu(element, item) {
      if (item) this.pumpRoomId = item.id;
      event.preventDefault(); // 阻止浏览器默认右键菜单
      this.isContextMenuVisible = true;
      this.$nextTick(() => {
        this.mouseX = event.clientX;
        this.mouseY = event.clientY;
        this.currentElement = element;
        this.currentMenuItems = this.menuItems[element];
      });
    },
    hideContextMenu() {
      this.isContextMenuVisible = false;
    },
    handleMenuItemClick(item, pumpRoomId) {
      // 处理菜单项点击事件逻辑
      switch (item.id) {
        case 1:
          this.jumpHandle("./configuration");
          break;
        case 2:
          this.deviceStatistical();
          break;
        case 3:
          mapCoordinate()
            .then((res) => {
              if (this.$refs.map) this.$refs.map.init(res.data.reverse());
            })
            .finally(() => {});
          break;
        case 4:
          this.$refs.map.getCurrentLocation();
          break;
        case 5:
          this.$refs.map.getFocus();
          break;
        case 6:
          if (this.infoType == this.$t("i18n_774")) this.jumpHandle("./alarm");
          if (this.infoType == this.$t("i18n_775")) this.jumpHandle("./event");
          break;
        case 7:
          this.realTimeAlarmOrEvent();
          break;
        case 8:
          this.jumpHandle("./alarm");
          break;
        case 9:
          this.alarmStatistical();
          break;
        case 10:
          this.jumpHandle("./task");
          break;
        case 11:
          this.inspectionProgressVo();
          break;
        case 12:
          this.jumpHandle("./monitor");
          break;
        case 13:
          break; //监控
        case 14:
          let query = { pumpRoomId: item.pumpRoomId };
          this.jumpHandle("./pumpHouse", query);
          break;
        case 15:
          this.pumpRoom();
          break;
        default:
          break;
      }
    },
    format(evevt, item) {
      return `${item.value}` + " | " + item.total;
    },
    //首页-设备统计
    deviceStatistical() {
      this.deviceInfo = [];
      this.oracleInfo = [];
      deviceStatistical()
        .then((res) => {
          this.deviceInfo = res.data.data.map((e) => {
            e.percentage = parseInt((e.maintenanceDevice / e.value) * 100);
            return e;
          });
          this.oracleInfo = res.data;
        })
        .finally(() => {});
    },
    //首页-实时告警、事件
    realTimeAlarmOrEvent() {
      this.infoLoading = true;
      setTimeout(() => {
        this.info = []
        realTimeAlarmOrEvent()
          .then((res) => {
          const chuck = (arr, len) => {
            return arr.reduce((a, b) => {
              if (a.length > 0) {
                let lastArr = a.pop();
                if (lastArr.length < len) return [...a, [...lastArr, b]];
                return [...a, lastArr, [b]];
              }

              return [...a, [b]];
            }, []);
          };
          // this.noticeInfo = chuck(res.data, 7);
          this.noticeInfo = res.data, 7
          })
          .finally(() => {
            this.infoLoading = false;
          });   
      }, 800);
    },
    //首页-告警统计
    alarmStatistical() {
      this.alarmStatisticalInfo = [];
      alarmStatistical()
        .then((res) => {
          this.alarmStatisticalInfo = res.data;
          this.alarmStatisticalInfo.series = res.data.series.map((e) => {
            e.type = "line";
            e.stack = "Total";
            return e;
          });
          this.seriesNameList = res.data.series.map((e) => {
            return e.name;
          });
        })
        .finally(() => {});
    },
    //首页-巡检进度
    inspectionProgressVo() {
      this.loading = true;
      let data = {
        pumpRoomId: this.where.pumpRoomId,
      };
      inspectionProgressVo(data)
        .then((res) => {
          this.progress = res.data.map((e) => {
            if (e.name == this.$t("i18n_776")) e.enName = "Weekly";
            if (e.name == this.$t("i18n_777")) e.enName = "Monthly";
            if (e.name == this.$t("i18n_778")) e.enName = "Quarterly";
            if (e.name == this.$t("i18n_779")) e.enName = "Annual";
            return e;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //泵房列表
    pumpRoom(e) {
      pumpRoom()
        .then((res) => {
          if(e){
            const result = this.compareArraysByProperty(this.pumpRoomList,res.data,'healthyScore')
            if(result) return false
          } 
          const chuck = (arr, len) => {
            return arr.reduce((a, b) => {
              if (a.length > 0) {
                let lastArr = a.pop();
                if (lastArr.length < len) return [...a, [...lastArr, b]];
                return [...a, lastArr, [b]];
              }

              return [...a, [b]];
            }, []);
          };
          this.pumpRoomList = res.data;
          let nowPumpId = setting.takePumpId();
          if (nowPumpId) {
            this.pumpRoomList.forEach((el) => {
              if (el.id == nowPumpId) {
                this.where.pumpRoomId = el.id;
              }
            });
          } else {
            this.where.pumpRoomId = this.pumpRoomList[0].id;
          }
          this.swiperList = chuck(res.data, 2);
          if(!e) this.pumpRoomChange()
        })
        .finally(() => {});
    },
    //判断两个数组里对应下标的对应属性是否相同
    compareArraysByProperty(arr1, arr2, property) {
      if (arr1.length !== arr2.length) {
        return false; // 如果两个数组长度不相等，则直接返回false
      }
      
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i][property] !== arr2[i][property]) {
          return false; // 如果对应下标的属性值不相等，则直接返回false
        }
      }
      
      return true; // 如果所有对应下标的属性值都相等，则返回true
    },
    //地图
    mapCoordinate() {
      mapCoordinate()
        .then((res) => {
          setTimeout(() => {
            this.pumpRoomList.forEach((v) => {
              res.data.forEach((k) => {
                if (v.id == k.id) {
                  k.url = v.configurationPoster;
                }
              });
            });
            if (this.$refs.map) this.$refs.map.init(res.data.reverse());
          }, 3000);
        })
        .finally(() => {});
    },
    deviceFormat(percentage, item) {
      return item.maintenanceDevice;
    },
    chartOption(e) {
      var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const midPadding = screenWidth<1280?12:18
      var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const leftWidth = screenWidth<1280?'100%':'100%'
      //当前视口宽度
      let nowClientWidth = document.documentElement.clientWidth;
      // 换算方法
      function nowSize(val, initWidth = 1920) {
          return val * (nowClientWidth / initWidth);
      };
      const option = {
        tooltip: {
          formatter: "{a} <br/>{b} : {c}%",
        },
        series: [
          {
            type: "gauge",
            radius: leftWidth,
            startAngle: "225",
            endAngle: "-45",
            pointer: {
              show: false,
            },
            detail: {
              show: true,
              formatter: "{value}%",
            },
            data: [
              {
                detail: {
                  show: true,
                  fontSize: nowSize(15),
                  fontWeight: "bold",
                  color:
                    e.healthyLevel == 3
                      ? "#3AC460"
                      : e.healthyLevel == 2
                      ? "#FF8437"
                      : "#FF0000",
                  fontFamily: "DINAlternate-Bold, DINAlternate",
                  width: nowSize(18),
                  height: nowSize(18),
                  offsetCenter: ["0", "0%"],
                  borderRadius: [24, 24, 24, 24],
                  padding: midPadding,
                  backgroundColor:
                    e.healthyLevel == 3
                      ? {
                          type: "linear",
                          x: 0,
                          y: 0,
                          x2: 0,
                          y2: 3,
                          colorStops: [
                            {
                              offset: 0,
                              color: "#FFFFFF", // 0% 处的颜色
                            },
                            {
                              offset: 1,
                              color: "#3BC562", // 100% 处的颜色
                            },
                          ],
                          global: false, // 缺省为 false
                        }
                      : e.healthyLevel == 2
                      ? {
                          type: "linear",
                          x: 0,
                          y: 0,
                          x2: 0,
                          y2: 2,
                          colorStops: [
                            {
                              offset: 0,
                              color: "#FFFFFF", // 0% 处的颜色
                            },
                            {
                              offset: 1,
                              color: "#FF823B", // 100% 处的颜色
                            },
                          ],
                          global: false, // 缺省为 false
                        }
                      : {
                          type: "linear",
                          x: 0,
                          y: 0,
                          x2: 0,
                          y2: 2,
                          colorStops: [
                            {
                              offset: 0,
                              color: "#FFFFFF", // 0% 处的颜色
                            },
                            {
                              offset: 1,
                              color: "#FF0000", // 100% 处的颜色
                            },
                          ],
                          global: false, // 缺省为 false
                        },
                },
                value: e.healthyScore,
                name:
                  e.healthyLevel == 3
                    ? this.$t("i18n_780")
                    : e.healthyLevel == 2
                    ? this.$t("i18n_781")
                    : this.$t("i18n_782"),
                title: {
                  show: true,
                  offsetCenter: ["0", "65%"],
                  fontSize: nowSize(11),
                  fontFamily: "PingFangSC-Medium, PingFang SC",
                  color: "#333333",
                },
                itemStyle: {
                  color:
                    e.healthyLevel == 3
                      ? {
                          type: "linear",
                          x: 0,
                          y: 0.5,
                          x2: 1,
                          y2: 0,
                          colorStops: [
                            {
                              offset: 0,
                              color: "#74F2B9", // 0% 处的颜色
                            },
                            {
                              offset: 1,
                              color: "#23D352", // 100% 处的颜色
                            },
                          ],
                          global: false, // 缺省为 false
                        }
                      : e.healthyLevel == 2
                      ? {
                          type: "linear",
                          x: 0,
                          y: 0.5,
                          x2: 1,
                          y2: 0,
                          colorStops: [
                            {
                              offset: 0,
                              color: "#FF7D2B", // 0% 处的颜色
                            },
                            {
                              offset: 1,
                              color: "#FF977F", // 100% 处的颜色
                            },
                          ],
                          global: false, // 缺省为 false
                        }
                      : {
                          type: "linear",
                          x: 0,
                          y: 0,
                          x2: 0,
                          y2: 1,
                          colorStops: [
                            {
                              offset: 0,
                              color: "#FF0000", // 0% 处的颜色
                            },
                            {
                              offset: 1,
                              color: "#ffd4d4", // 100% 处的颜色
                            },
                          ],
                          global: false, // 缺省为 false
                        },
                  // e.healthyLevel == 3
                  //   ? "#74F2B9"
                  //   : e.healthyLevel == 2
                  //   ? "#FFAB91"
                  //   : "#E62B2B",
                },
              },
            ],
            itemStyle: {
              color: "#FFAB91",
            },
            progress: {
              show: true,
              width:nowSize(23),
            },
            pointer: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                width: nowSize(23),
                // shadowBlur: 15,
                color: [
                  [
                    1,
                    e.healthyLevel == 3
                      ? "#def7e3"
                      : e.healthyLevel == 2
                      ? "#F9E1CF"
                      : "#ffd4d4",
                  ],
                ],
                shadowColor: "#B0C4DE",
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                opacity: 1,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            anchor: {
              show: false,
            },
            title: {
              show: false,
            },
          },
        ],
      };
      return option;
    },
    //跳告警或事件
    jumpEventAlarm(item) {
      if (item.type == this.$t("i18n_783")) {
        this.jumpHandle("./alarm");
      } else {
        this.jumpHandle("./event");
      }
    },
    allLineOption() {
      let category = [];
      // if(this.oracleInfo.list){
      //   this.oracleInfo.list.forEach((e) => {
      //     if(e.maintenanceDevice!=0){
      //       let obj = {
      //         value: e.maintenanceDevice,
      //         name: e.name,
      //       };
      //       category.unshift(obj);
      //     }
      //   });
      // }
      this.oracleInfo.list.forEach((e) => {
        let obj = {
          value: e.maintenanceDevice,
          name: e.name,
        };
        category.unshift(obj);
      });
      let yName = []; // y轴名称
      let bgData = []; // 最大值用作背景显示的数据
      let maxValue = 30; //最大值
      category.forEach((element) => {
        yName.push(element.name);
        bgData.push({
          name: element.name,
          value: maxValue,
          type: element.type,
        });
      });
      const option = {
        backgroundColor: "#FFF",
        tooltip: {
          trigger: 'item',
          formatter: function(params) {
            var htmlStr = '';
            var color = params.color; //图例颜色
            var seriesName = '设备统计'; //图例名称
            var xName = params.name; //x轴的名称
            var value = params.value; //y轴值
            var htmlStr = '<div>';
            htmlStr += seriesName + '<br/>';
            htmlStr +=
              '<span style="margin-right: 5px; font-size: 16pt; font-family: Consolas;display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color:' +
              color + ' ;"></span>';
            htmlStr += "<span style='min-height: 20pt; font-size: 10pt'>";
            htmlStr += xName;
            htmlStr += '</span>';
            htmlStr += "<span style='min-height: 20pt; font-size: 10pt; margin-left: 20px'>";
            // console.log(params.data.length);
            if (!value.length) {
              htmlStr += category[params.dataIndex].value
            } else {
              htmlStr += value[params.seriesIndex + 1] + '万元';//选择对应value的坐标
            }
            htmlStr += '</span>'
            htmlStr += '</div>';
            return htmlStr;
          }
        },
        xAxis: {
          max: maxValue,
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        dataZoom: [
          {
            type: "inside",
            startValue: category[category.length-1].value - 3,
            endValue: 7,
            yAxisIndex: 0,
            // zoomLock :true,
            zoomOnMouseWheel: false,
            moveOnMouseWheel: true,
          },
          {
            startValue: category[category.length-1].value - 3,
            endValue: 7,
            yAxisIndex: 0,
            // zoomLock :true,
            zoomOnMouseWheel: false,
            moveOnMouseWheel: true,
            handleSize: "0%",
            width: 0,
            right: "0%",
          },
        ],
        grid: {
          left: 0,
          top: 20,
          right: 0,
          bottom: 0,
        },
        yAxis: [
          {
            // 每条图形上面的文字
            inverse: false,
            data: yName,
            axisLabel: {
              padding: [5, 0, 45, 0],
              inside: true,
              textStyle: {
                fontSize: 12,
                fontWeight: 400,
                color: "#333333",
                align: "left",
                zlevel:999
              },
              formatter: "{value}",
              rich: {
                a: {
                  color: "transparent",
                  lineHeight: 20,
                  fontSize: 14,
                  shadowColor: "rgba(0, 0, 0, 1)",
                  shadowBlur: 10,
                },
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
          // {
          //   // y轴最右侧的文字
          //   axisTick: "none",
          //   axisLine: "none",
          //   type: "category",
          //   axisLabel: {
          //     margin: 10,
          //     textStyle: {
          //       color: "#31D55E",
          //       fontSize: "14",
          //     },
          //   },
          //   data: category,
          // },
        ],
        series: [
          {
            // 背景样式
            name: "背景",
            type: "bar",
            barWidth: 18,
            stack: "总量",
            barGap: "-100%",
            symbol: "fixed",
            symbolRepeat: "repeat",
            legendHoverLink: false,
            itemStyle: {
              normal: {
                color: "#F6F6F6",
              },
            },
            data: bgData,
            animation: false, //关闭动画
            z: 0,
          },
          {
            name: "info",
            // 内（显示的内容）
            type: "bar",
            barGap: "-100%",
            barWidth: 18,
            legendHoverLink: false,
            silent: true,
            itemStyle: {
              normal: {
                color: function (params) {
                  var color;
                  if (params.dataIndex % 2 != 0) {
                    color = {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 1,
                      y2: 0,
                      colorStops: [
                        {
                          offset: 0,
                          color: "#fbfbfb", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#26D456", // 100% 处的颜色
                        },
                      ],
                    };
                  } else {
                    color = {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 1,
                      y2: 0,
                      colorStops: [
                        {
                          offset: 0,
                          color: "#fbfbfb", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#EF9D11", // 100% 处的颜色
                        },
                      ],
                    };
                  }
                  return color;
                },
              },
            },
            data: category,
            z: 1,
            animationEasing: "elasticOut",
          },
        ],
      };
      return option;
    },
  },
  watch: {
    newCameraList:{
      immediate: true,
      deep: true,
      handler(e) {
        if(e){
            let newArr = this.sortByOtherArray(this.newCameraList,this.cameraList)
            this.yepList = newArr
            console.log(this.yepList,'this.yepList.yepList')
          // this.newCameraList = this.sortByIds(this.cameraList,this.newCameraList)
        }
      },
    }
  },
  destroyed() {
    if(this.newCameraList.length){
      this.newCameraList.forEach((el)=>{
        if(setting.takeToken()){
          this.goEndTl(el);
        }
      })
    }
  },
};
</script>
<style scoped lang="scss">
.player {
		width: 100%;
    height: 100%;
  .video-player-page {
    height: 100%;
    ::v-deep {
      .video-js {
        width: 100%;
        height: 100%;
        video{
          object-fit: contain;
        }
      }
    }
  }
}

@import "@/assets/style/home.scss";
.progress-title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}
.chart-title {
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  // line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
::v-deep {
  .el-progress{
    display: flex;
  }
  .el-progress-bar{
    flex: 0.9;
  }
  .el-progress__text{
    flex: 0.1;
    white-space: nowrap;
  }
  .el-progress-bar__outer {
    height: 14px !important;
  }
  .el-empty__image {
    width: 100px;
  }
  .el-carousel__container{
    height:100% !important;
  }
}
.info {
  cursor: pointer;
}
// .el-carousel__item h3 {
//   color: #475669;
//   font-size: 14px;
//   opacity: 0.75;
//   line-height: 150px;
//   margin: 0;
// }

// .el-carousel__item:nth-child(2n) {
//   background-color: #99a9bf;
// }

// .el-carousel__item:nth-child(2n + 1) {
//   background-color: #d3dce6;
// }
.healthyChart{
  height: 138px;
}
.totalTitle{
  margin-bottom: 20px;
}
</style>
