// 分页
export const dataPaging = {
  data() {
    return {
      total: 3,  // 数据总量
      currentPage: 1, //初始页
      pagesize: 10,    //    每页的数据
      pagingListVlue:  this.$i18n.locale=='en'?'10/page':'10条/页',
      pagingList: [
        {
          label: this.$i18n.locale=='en'?'10/page':'10条/页',
          value: 10
        }, {
          label: this.$i18n.locale=='en'?'30/page':'30条/页',
          value: 30
        }, {
          label: this.$i18n.locale=='en'?'50/page':'50条/页',
          value: 50
        }, {
          label: this.$i18n.locale=='en'?'100/page':'100条/页',
          value: 100
        },
      ],
    }
  },
  methods: {
    // 页面更新 刷新数据
    pagingListchange(evt) {
      this.pagesize = evt;
      this._getData();
    },
    toLastData(num) {
      this.currentPage = num;
      this._getData();
    },
    // 修改每页条数
    pagingListchanges(evt) {
      this.pagesize = evt;
      this.currentPage = 1;
      this._getData();
    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange(size) {
      this.pagesize = size;
    },
    // 切换页数
    handleCurrentChange: function (currentPage) {
      this.currentPage = parseInt(currentPage);
      this._getData();
    },

  },
}

// 子分页
export const pagingTaggle = {
  data() {
    return {
      currentPage: 1, //初始页
      pagesize: 10,    //    每页的数据
      pagingListVlue:  this.$i18n.locale=='en'?'10/page':'10条/页',
      pagingList: [
        {
          label: this.$i18n.locale=='en'?'10/page':'10条/页',
          value: 10
        }, {
          label: this.$i18n.locale=='en'?'30/page':'30条/页',
          value: 30
        }, {
          label: this.$i18n.locale=='en'?'50/page':'50条/页',
          value: 50
        }, {
          label: this.$i18n.locale=='en'?'100/page':'100条/页',
          value: 100
        },
      ],
    }
  },
  methods: {
    // 页面更新 刷新数据
    pagingListchange(num) {
      this.pagesize = num;
      this.$emit('pagingListchange',num)
    },
    toLastData(num) {
      this.currentPage = num;
      this.$emit('toLastData',num)
    },
    // 修改每页条数
    pagingListchanges(num) {
      this.pagesize = num;
      this.currentPage = 1;
      this.$emit('pagingListchanges',num)
    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange(size) {
      this.pagesize = size;
    },
    // 切换页数
    handleCurrentChange (num) {
      this.currentPage = num;
      this.$emit('handleCurrentChange',num)
    },

  },
}



// 高度
export const scrollTop = {
  data() {
    return {
      cliHeight: '',
      headHeight: ''
    }
  },
  mounted() {
    this.cliHeight = document.documentElement.clientHeight;
    this.headHeight = this.$refs.heade.offsetTop;
  }
}

// 定时器
export const timing = {
  data() {
    return {
      intervalId: null,
    }
  },
  mounted() {
  },
  destroyed() {
    // 在页面销毁后，清除计时器
    this.clear();
  },
  methods: {
    // 停止定时器
    clear() {
      clearInterval(this.intervalId); //清除计时器
      this.intervalId = null; //设置为null
    },
  }
}