import request from "@/config/request";

//监控-获取泵房列表
export function monitorPumpRoom(params) {
    return request({
        url: '/equipment/app/monitor/pumpRoomList',
        method: 'GET',
        params,
    })
}

//泵房-健康状态详情
export function healthStatusDetails(params) {
    return request({
        url: '/equipment/app/pumpRoom/healthStatusDetails',
        method: 'GET',
        params,
    })
}

//泵房环境数据
export function pumpRoomEnvironmentData(params) {
    return request({
        url: '/equipment/app/homePage/pumpRoomEnvironmentData',
        method: 'GET',
        params,
    })
}

//泵房-机组tab
export function pumpRoomPumpGroup(params) {
    return request({
        url: '/equipment/pc/pumpRoom/rightMenu',
        method: 'GET',
        params,
    })
}

//泵房-机组设备属性
export function attrList(id) {
    return request({
        url: '/equipment/app/pumpRoom/attrList/'+id,
        method: 'GET',
    })
}

//泵房-末端试水机组设备属性
export function endWaterTestingAttrList(id) {
    return request({
        url: '/equipment/pc/pumpRoom/endWaterTestingAttrList/'+id,
        method: 'GET',
    })
}

//泵房-获取时间段机组设备属性的值
export function attrDataList(data) {
    return request({
        url: '/equipment/app/pumpRoom/attrDataList',
        method: 'POST',
        data
    })
}

//机组启动
export function deviceStartup(data) {
    return request({
        url: '/equipment/app/pumpRoom/deviceStartup',
        method: 'POST',
        data,
    })
}
//机组关闭
export function changeAttr(data) {
    return request({
        url: '/equipment/app/pumpRoom/deviceStop',
        method: 'POST',
        data,
    })
}

//获取设备类型属性
export function getDeviceTypeAttrInfo(params) {
    return request({
        url: '/equipment/pc/getDeviceTypeAttrInfo',
        method: 'GET',
        params,
    })
}

//单位切换
export function setAttrUnit(data) {
    return request({
        url: '/equipment/app/setAttrUnit',
        method: 'POST',
        data,
    })
}

//获取单位列表
export function getAttrUnitList(params) {
    return request({
        url: '/equipment/app/getAttrUnitList',
        method: 'GET',
        params,
    })
}

//查询设备属性run的启动频率
export function getStartingFrequency(params) {
    return request({
        url: '/equipment/app/pumpRoom/getStartingFrequency/'+params,
        method: 'GET'
    })
}

//开关
export function endTestingTestSwitch(data) {
    return request({
        url: '/equipment/app/pumpRoom/endTestingTestSwitch',
        method: 'POST',
        data,
    })
}

//查询设备属性run的启动频率
export function startTranscode(params) {
    return request({
        url: '/media/camera/startTranscode',
        method: 'GET',
        params
    })
}
