<template>
  <!-- <div :id="`video_container_${index}`" class="video_container"></div> -->
  <div :id="uid" class="video_container">
    <div class="inpictrue" @click="requestPictureInPicture">{{$t("i18n_1")}} </div>
  </div>
</template>
<script>
import { TcPlayer } from 'tcplayer'
import {
  pump_room_list,
  camera_startTranscode,
  flow_startTranscode,
  camera_catchPic,
} from "@/api/monitor"
import axios from "axios";
import setting from "@/config/setting";

export default {
  name: 'Cviewplayer',
  props: {
    //默认监控图片
    Defaultimage: { type: String, default: '' },
    //视频的url
    videoUrl: { type: String, default: 'https://project-ali.oss-cn-beijing.aliyuncs.com/2023/10/10/644_1691483049_20231010181414A007.mp4' },
    //视频的坐标，用于判断div的id赋值到对应的容器
    // index: { type: Number, default: 0 },
    //是否播放
    isPlay: { type: Boolean, default: false },
    id: { type: String, default: '' },
  },
  data() {
    return {
      uid: this._uid,
      player: [],
      poster: '' /* 视频封面 */,
    }
  },
  created() {
    window.addEventListener('unload', this.handleBeforeUnload);

    this.$nextTick(() => {
      this.$nextTick(() => {
        this.initPlayer()
      })
    })
  },
  watch: {
    isPlay:{
      immediate: true,
      handler(e) {
        console.log(e,'isPlay')
        this.$nextTick(() => {
          if (this.isPlay) {
            // this.play()
          }else{
            console.log('关闭关闭')
            // this.goEndTl()
          }
        })
      }
    },
    // "$route.path": {
    //   immediate: true,
    //   handler(e) {
    //     if(e){
    //       console.log(e,'routerouteroute')
    //       this.goEndTl()
    //     }
    //   },
    // },
  },
  beforeDestroy(){
    window.removeEventListener('unload', this.handleBeforeUnload);

    console.log(this.isPlay,'this.isPlay????')
    console.log(this.player[0],'this.player[0]this.player[0]')
    if(this.isPlay==true)this.goEndTl()
    this.player[0].destroy()
  },
  methods: {
    async handleBeforeUnload(event) {
      e = e || window.event
      if (e) {
        e.returnValue = '您是否确认离开此页面-您输入的数据可能不会被保存'
      }
      
      var params = { tasker:this.cameratasker }
      await axios({
        method: "GET",
        url: "http://localhost:9000/media/flow/stopByTasker",
        params,
        headers: {
          Authorization: setting.takeToken(),
        },
        responseType: "blob",
      })
        .then((res) => {
        })
        .finally(() => {});
    },
    initPlayer(falg) {
      if (this.player.length) {
        this.player.forEach((item) => {
          item.destroy()
        })
        this.player = []
      }
      var options = {
        //视频地址
        m3u8: this.videoUrl,
        flv: this.videoUrl,
        //是否自动播放
        autoplay: falg?true:false,
        muted:true,
        preload: true,
        //暂停时视频封面
        poster: { style: 'cover', src: this.Defaultimage },
        coverpic: this.Defaultimage,
        // live: live,
        width: '100%',
        height: '100%',
        x5_player: true,
        x5_type: 'h5-page',
        x5_fullscreen: true,
        wording: {
          1: '网络错误，请检查网络配置或者播放连接是否正确',
          1002: '即将开始，请稍等',
          2032: '请求视频失败，请检查网络',
          2048: '请求m3u8文件失败，多是网络错误或者跨域问题',
          4: ''
        },
        flash: false, //是否优先使用 flash 播放视频
        pausePosterEnabled: true /* 暂停时显示封面 默认为true */,
        listener: (msg) => {
          console.log(msg,'msg')
          if (msg.type == 'play') {
            //加载完事件
            this.getTldz()
          }
          if (msg.type == 'load') {
            //加载完事件

            // this.player.play()
          }
          if (msg.type == 'ended') {
            //播放完成事件
            // this.player[this.index].close()
          }
          // 播放失败，重新连接
          if (msg.type == 'error') { 
            //加载完事件
            this.getTldz()
            // console.log(msg,'msg')
            // setTimeout(() => {
            //   if (this.player[0].options.src == undefined) return false
            //   if(this.isPlay===true) this.player[0].load() // 重连
            // }, 4000)
          }
        },
      }
      // 初始化
      // this.player.push(new TcPlayer('video_container_' + this.index, options))
      this.player.push(new TcPlayer(this.uid, options))
    },
    // 播放或暂停
    play() {
      if (this.player.length) {
        this.player.forEach((item) => {
          //如果没有地址则跳过
          if (item.options.src == undefined) return false
          item?.play()
        })
      } else {
        this.$message.warning('暂无视频播放源，请检查设置')
      }
    },
    requestPictureInPicture(){
      console.log(this.player[0].video,'playerplayerplayer')
      // 进入画中画
      this.player[0].video.el.requestPictureInPicture();
    },
    over(){
      this.goEndTl()
      // this.player[0].destroy()
      setTimeout(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.initPlayer()
          })
        })
      }, 500);
    },
    // 销毁
    goEndTl() {
      let data = {
        tasker: this.cameratasker
      }
      flow_startTranscode(data).then(res => {
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    // 根据摄像头获取拉流地址
    getTldz() {
      let data = {
        id: this.id
      }
      camera_startTranscode(data).then(res => {
        console.log(res, "获取到的拉流地址")
        this.videoUrl =  '/srs'+res.data.urlName +'.flv'
        this.cameratasker = res.data.tasker
        this.$emit('getCameratasker',res.data.tasker)
        this.isPlay = true
        this.initPlayer2()
        this.player[0].play();
      }).catch(err => {
      })
    },
    initPlayer2() {
      if (this.player.length) {
        this.player.forEach((item) => {
          item.destroy()
        })
        this.player = []
      }
      var options = {
        //视频地址
        m3u8: this.videoUrl,
        flv: this.videoUrl,
        //是否自动播放
        autoplay: false,
        muted:true,
        preload: true,
        //暂停时视频封面
        poster: { style: 'cover', src: this.Defaultimage },
        coverpic: this.Defaultimage,
        // live: live,
        width: '100%',
        height: '100%',
        x5_player: true,
        x5_type: 'h5-page',
        x5_fullscreen: true,
        wording: {
          1: '网络错误，请检查网络配置或者播放连接是否正确',
          1002: '即将开始，请稍等',
          2032: '请求视频失败，请检查网络',
          2048: '请求m3u8文件失败，多是网络错误或者跨域问题',
        },
        flash: false, //是否优先使用 flash 播放视频
        pausePosterEnabled: true /* 暂停时显示封面 默认为true */,
        listener: (msg) => {
          // 播放失败，重新连接
          if (msg.type == 'error') {
            // console.log(msg,'msg')
            setTimeout(() => {
              if (this.player[0].options.src == undefined) return false
              if(this.isPlay===true) this.player[0].load() // 重连
            }, 4000)
          }
        },
      }
      // 初始化
      // this.player.push(new TcPlayer('video_container_' + this.index, options))
      this.player.push(new TcPlayer(this.uid, options))
    },
  },
}
</script>
<style lang="scss" scoped>
.video_container {
  width: 100%;
  height: 100%;
  position: relative;
}
.inpictrue{
  position: absolute;
  right: 10px;
  top: 10px;
  color: #FFF;
  z-index: 999;
  cursor: pointer;
}
::v-deep {

  // 修改视频提示语位置
  .vcp-error-tips {
    padding-top: 66px;
  }

  .vcp-controls-panel {
    height: 30px
  }

  .vcp-fullscreen-toggle,
  .vcp-volume-icon,
  .vcp-playtoggle {
    width: 0.17rem;
    height: 0.17rem;
  }

  .vcp-player,
  .vcp-panel-bg {
    border-radius: 12px;
    overflow: hidden;
  }

  .vcp-timelabel {
    display: none;
  }
}
</style>


