import request from "@/config/request";

//获取泵房列表
export function pumpRoom(params) {
    return request({
        url: '/equipment/open_api/pumpRoom',
        method: 'GET',
        params,
    })
}

//根据泵房ID获取设备列表
export function byPumpRoomId(params) {
    return request({
        url: '/equipment/open_api/deviceByPumpRoomId',
        method: 'GET',
        params,
    })
}

//根据设备ID获取下级设备列表
export function byDeviceId(params) {
    return request({
        url: '/equipment/open_api/deviceByDeviceId',
        method: 'GET',
        params,
    })
}

//机组
export function device(params) {
    return request({
      url: '/equipment/device/list',
      method: 'get',
      params,
    })
  }

//头部闪烁标列表
export function breathingLightList(params) {
    return request({
        url: '/equipment/app/top/breathingLightList',
        method: 'GET',
        params,
    })
}

//获取告警声音配置
export function getAlarmConfigSet(params) {
    return request({
        url: '/system/config/getAlarmConfigSet',
        method: 'GET',
        params,
    })
}

//获取连接状态
export function linkState(params) {
    return request({
        url: '/equipment/app/top/linkState',
        method: 'GET',
        params,
    })
}
