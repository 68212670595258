<template>
    <div :id="'aliplayer' + this._uid" class="video_container">
        <div class="inpictrue" @click="requestPictureInPicture">{{ $t("i18n_262") }} </div>
    </div>
</template>

<script>
import {
    camera_startTranscodes,
    flow_startTranscode
} from "@/api/monitor"
export default {
    name: 'aliplayer',
    props: {
        videoUrl: {
            type: String,
            default: '',
        },
        isLive: {
            type: Boolean,
            default: false,
        },
        videoId: {
            type: Number,
            default: '',
        },
        flag: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            aliplayer: null,
            skinLayout: [
                { name: 'bigPlayButton', align: 'blabs', x: 30, y: 80 },
                {
                    name: 'H5Loading',
                    align: 'cc',
                },
                // { name: 'errorDisplay', align: 'tlabs', x: 0, y: 0 },
                { name: 'infoDisplay' },
                { name: 'tooltip', align: 'blabs', x: 0, y: 56 },
                { name: 'thumbnail' },
                {
                    name: 'controlBar',
                    align: 'blabs',
                    x: 0,
                    y: 0,
                    children: [
                        { name: 'progress', align: 'blabs', x: 0, y: 44 },
                        { name: 'playButton', align: 'tl', x: 15, y: 12 },
                        { name: 'timeDisplay', align: 'tl', x: 10, y: 7 },
                        { name: 'fullScreenButton', align: 'tr', x: 10, y: 12 },
                        // { name: 'subtitle', align: 'tr', x: 15, y: 12 },
                        { name: 'setting', align: 'tr', x: 15, y: 12 },
                        { name: 'volume', align: 'tr', x: 5, y: 10 },
                    ],
                },
            ],
            skinLayoutLive: [
                { name: 'bigPlayButton', align: 'blabs', x: 30, y: 80 },
                {
                    name: 'H5Loading',
                    align: 'cc',
                },
                // { name: 'errorDisplay', align: 'tlabs', x: 0, y: 0 },
                { name: 'infoDisplay', align: 'cc' },
                {
                    name: 'controlBar',
                    align: 'blabs',
                    x: 0,
                    y: 0,
                    children: [
                        // { name: 'liveDisplay', align: 'tlabs', x: 15, y: 6 },
                        { name: 'playButton', align: 'tl', x: 15, y: 30 },
                        { name: 'fullScreenButton', align: 'tr', x: 10, y: 10 },
                        // { name: 'subtitle', align: 'tr', x: 15, y: 12 },
                        // { name: 'setting', align: 'tr', x: 15, y: 12 },
                        { name: 'volume', align: 'tr', x: 5, y: 10 },
                    ],
                },
            ],
            cameratasker: ''
        };
    },
    mounted() {
        this.getTldz()
    },

    watch: {

    },
    methods: {
        // 根据摄像头获取拉流地址
        getTldz() {
            let data = {
                id: this.videoId
            }
            camera_startTranscodes(data).then(res => {
                if (this.aliplayer) {
                    this.aliplayer.dispose();
                    this.aliplayer = null;
                }
                this.cameratasker = res.data.tasker
                this.videoUrl = res.data.playUrl
                this.$nextTick(() => {
                    this.play()
                })
            }).catch(err => {
            })
        },
        /**
         * @source 配置文档  https://help.aliyun.com/zh/vod/developer-reference/api-operations?spm=a2c4g.11186623.0.0.1a1a3f77WYaLVo#section-11l-e4g-lbr
         */
        play() {
            let id = 'aliplayer' + this._uid
            this.aliplayer = new Aliplayer(
                {
                    /* 内置 */
                    id: id,
                    width: '100%',
                    height: '100%',
                    autoplay: this.flag,
                    // 循环播放
                    rePlay: false, //
                    playsinline: true,
                    // 自动加载
                    preload: true,
                    controlBarVisibility: 'hover',
                    // 指定h5播放器
                    useH5Prism: true,
                    skinLayout: this.skinLayoutLive,
                    /* 配置 */
                    // 是否直播 进度条存在与否
                    isLive: this.isLive,
                    // 播放地址
                    source: this.videoUrl,
                    cover: ''
                },
                function (player) {
                    console.log('The player is created');
                    // player.play()
                }
            );

        },
        requestPictureInPicture() {
            // 进入画中画
            this.aliplayer._el.querySelector('video').requestPictureInPicture();
        },
        destroyPlayer() {
            if (this.aliplayer) {
                this.aliplayer.dispose();
                this.aliplayer = null;
            }
        },
        // 销毁
        goEndTl() {
            let data = {
                tasker: this.cameratasker
            }
            flow_startTranscode(data).then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            })
        },
    },
    destroyed() {
        if (this.aliplayer) {
            this.aliplayer.dispose();
        }
    },
};
</script>

<style lang="scss" scoped>
.video_container {
    width: 100%;
    height: 100%;
    position: relative;
}

.inpictrue {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #FFF;
    z-index: 999;
    cursor: pointer;
}
</style>

<style>
.prism-player .prism-big-play-btn {
    left: 50% !important;
    bottom: 50% !important;
    transform: translate(-50%, 50%);
}
</style>
