/**
 * 登录状态管理
 */
import axios from 'axios';
import setting from '@/config/setting';

export default {
  namespaced: true,
  state: {
    // 当前用户信息
    user: setting.takeUser(),
    // 当前用户权限
    authorities: [],
    // 当前用户角色
    roles: [],
    // 当前用户的菜单
    menus: null,
    // 当前登录用户所拥有的权限节点
    permission: [],
    avatar:'',
    //当前泵房id
    nowPumpId:'',
    //消息已读数
    allRead:0
  },
  mutations: {
    // 修改值
    SET(state, obj) {
      state[obj.key] = obj.value;
    },
    // 修改token
    SET_TOKEN(state, obj) {
      setting.cacheToken(obj.token, obj.remember);
      state.token = obj.token;
      if (!obj.token) {
        state.user = {};
        state.menus = null;
        setting.cacheUser();
      }
    },
    // 设置权限
    SET_PERMISSION(state, data) {
      state.permission = data
    },
    SET_AVATAR: (state, avatar) => {
      state.user.avatar = avatar
    },
    SET_PUMPID: (state, id) => {
      state.nowPumpId = id
      setting.cachePumpId(id);
    },
    SET_AllREAD: (state,num) => {
      setting.cacheRead(num);
      state.allRead = num
    },
  },
  actions: {
    /**
     * 设置节点权限
     * @param {*} commit
     * @param {*} data
     */
    setPermission({commit}, data) {
      commit('SET_PERMISSION', data)
    },
    /**
     * 缓存token
     * @param commit
     * @param token {String, {token: String, remember: String}}
     */
    setToken({commit}, token) {
      let remember = true;
      if (typeof token === 'object') {
        remember = token.remember;
        token = token.token;
      }
      commit('SET_TOKEN', {token: token, remember: remember});
    },
    /**
     * 头像
     * @param commit
     */
    setAvatar({commit}, avatar) {
      commit('SET_AVATAR', avatar);
    },
    /**
     * 移除token
     * @param commit
     */
    removeToken({commit}) {
      commit('SET_TOKEN', {});
    },
    /**
     * 设置用户信息
     * @param commit
     * @param user {Object} 用户信息
     */
    setUser({commit}, user) {
      setting.cacheUser(user);
      commit('SET', {key: 'user', value: user});
    },
    /**
     * 设置用户权限
     * @param commit
     * @param authorities {Array<String>} 权限
     */
    setAuthorities({commit}, authorities) {
      commit('SET', {key: 'authorities', value: authorities});
    },
    /**
     * 设置用户角色
     * @param commit
     * @param roles {Array<String>} 角色
     */
    setRoles({commit}, roles) {
      commit('SET', {key: 'roles', value: roles});
    },
    /**
     * 设置泵房id
     * @param commit
     * @param id {Object} 用户信息
     */
    setPumpId({commit}, id) {
      console.log(id,'id')
      commit('SET_PUMPID', id);
    },
    /**
     * 设置全部已读
     * @param commit
     * @param id {Object} 用户信息
     */
    setAllRead({commit},num) {
      console.log(num,'num12312312312')
      commit('SET_AllREAD',num);
    },
  }
}
