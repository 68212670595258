import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/style/ruoyi.scss' // ruoyi css
import 'video.js/dist/video-js.css'
import './config/axios-config';
import moment from "moment";
import hls from "videojs-contrib-hls";
import videojs from 'video.js';
import "@/utils/dialogDrag"
// import "amfe-flexible/index.js";

(function flexible (window, document) {
  var docEl = document.documentElement
  var dpr = window.devicePixelRatio || 1

  // adjust body font size
  function setBodyFontSize () {
    if (document.body) {
      document.body.style.fontSize = (12 * dpr) + 'px'
    }
    else {
      document.addEventListener('DOMContentLoaded', setBodyFontSize)
    }
  }
  setBodyFontSize();

  // set 1rem = viewWidth / 10
  function setRemUnit () {
    var rem = docEl.clientWidth / 10
    docEl.style.fontSize = rem + 'px'
  }

  setRemUnit()

  // reset rem unit on page resize
  // window.addEventListener('resize', setRemUnit)
  window.addEventListener('pageshow', function (e) {
    if (e.persisted) {
      setRemUnit()
    }
  })

  // detect 0.5px supports
  if (dpr >= 2) {
    var fakeBody = document.createElement('body')
    var testElement = document.createElement('div')
    testElement.style.border = '.5px solid transparent'
    fakeBody.appendChild(testElement)
    docEl.appendChild(fakeBody)
    if (testElement.offsetHeight === 1) {
      docEl.classList.add('hairlines')
    }
    docEl.removeChild(fakeBody)
  }
}(window, document))


// i18n 配置
import messages from "@/locale"
import { locateLang, defaultObj } from "@/common/starageKeys"
// import 'swiper/css/swiper.css'
// 引入字体样式
import "@/assets/font/font.scss"

const i18nConfig = {
  locale: defaultObj[locateLang],
  messages,
  silentTranslationWarn: true // 关闭控制台提示
}

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n(i18nConfig)
import locale from 'element-ui/lib/locale' // 引入elementuiui语言包模块
locale.i18n((key, value) => i18n.t(key, value))

Vue.use(hls);
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$moment = moment
Vue.prototype.$video = videojs;
Vue.prototype.$url = process.env.VUE_APP_API_BASE_URL;
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
