import Vue from 'vue'
import VueRouter from 'vue-router'
import setting from '@/config/setting';
import Layout from "@/layout/index.vue"
import index from '@/views/home/index.vue'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test')
  },
  {
    path: "/",
    name: "Layout",
    component: Layout,
    redirect: '/',
    children: [{
        path: '/',
        name: 'index',
        component: index,
      },
      {
        path: '/pumpHouse',
        name: 'pumpHouse',
        component: () => import('@/views/pumpHouse/index')
      },
      {
        path: '/configuration',
        name: 'configuration',
        component: () => import('@/views/configuration/index')
      },
      {
        path: '/monitor',
        name: 'monitor',
        component: () => import('@/views/monitor/index')
      },
      {
        path: '/alarm',
        name: 'alarm',
        component: () => import('@/views/alarm/index')
      },
      {
        path: '/event',
        name: 'event',
        component: () => import('@/views/event/index')
      },
      {
        path: '/workOrder',
        name: 'workOrder',
        component: () => import('@/views/workOrder/index')
      },
      {
        path: '/task',
        name: 'task',
        component: () => import('@/views/task/index')
      },
      {
        path: '/data',
        name: 'data',
        component: () => import('@/views/data/index')
      },
      {
        path: '/expert',
        name: 'expert',
        component: () => import('@/views/expert/index')
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('@/views/user/index')
      },
      {
        path: '/endWater',
        name: 'endWater',
        component: () => import('@/views/endWater/index')
      },
      {
        path: '/stabilizingPump',
        name: 'stabilizingPump',
        component: () => import('@/views/stabilizingPump/index')
      },
      {
        path: '/electric',
        name: 'electric',
        component: () => import('@/views/electric/index')
      },
      {
        path: '/diesel',
        name: 'diesel',
        component: () => import('@/views/diesel/index')
      },
      {
        path: '/white',
        name: 'white',
        component: () => import('@/views/white/index')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const nextName = screenWidth<1920?'login':'login'
  if (to.name !== nextName) {
    if (setting.takeToken()) next()
    else next({ path: nextName})
  } else {
    if (setting.takeToken()) next({ name: 'home' })
    else next()
  }
})


document.title = '南方安美消防智能管理系统'
export default router
