
/**
 * @description: 防抖
 * @param {String} fn	源函数, wait	延迟毫秒数
 * @return {Promise} 返回值	目标函数
 */
export function debounce(fn, wait, immediate) {
  let timeout;

  return function () {
    const ctx = this;
    const args = arguments;

    const throttler = function () {
      timeout = null;
      fn.apply(ctx, args);
    };

    if (!immediate) clearTimeout(timeout);
    if (!immediate || !timeout) timeout = setTimeout(throttler, wait);
  };
};


/**
 * @description: 节流
 * @param {String} fn	源函数, wait	延迟毫秒数
 * @return {Promise} 返回值	目标函数
 */
export function throttle(fn, wait) {
  return debounce(fn, wait, true);
};



// * 默认缩放值
const scale = { width: "1", height: "1", };
// * 设计稿尺寸（px）
const baseWidth = 1920;
const baseHeight = 1225;
const baseProportion = parseFloat((baseWidth / baseHeight).toFixed(5));
export const calcRate = (screenDom) => {
  // 当前宽高比
  const currentRate = parseFloat(
    (window.innerWidth / window.innerHeight).toFixed(5)
  );
  if (screenDom) {
    if (currentRate > baseProportion) {
      // 表示更宽
      scale.width = (
        (window.innerHeight * baseProportion) /
        baseWidth
      ).toFixed(5);
      scale.height = (window.innerHeight / baseHeight).toFixed(5);
      screenDom.style.transform = `scale(${scale.width}, ${scale.height})`;
    } else {
      // 表示更高
      scale.height = (
        window.innerWidth /
        baseProportion /
        baseHeight
      ).toFixed(5);
      scale.width = (window.innerWidth / baseWidth).toFixed(5);
      screenDom.style.transform = `scale(${scale.width}, ${scale.height})`;
    }
  }
}
