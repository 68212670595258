import request from "@/config/request";

//账号密码登录
export function login(data) {
    return request({
      method: 'POST',
      url: '/auth/phoneLogin',
      notNeed: true,
      data
    })
  }

//获取二维码
export function getQrCode(params) {
  return request({
    method: 'GET',
    url: '/auth/getQrCode',
    params
  })
}

//查询登录状态 登录
export function getLoginStatus(params) {
  return request({
    method: 'GET',
    url: '/auth/getLoginStatus',
    params
  })
}

// 获取验证码
export function validateCode(params) {
  return request({
      method: 'GET',
      url: '/system/user/resetPassword/'+params.phone+'/validateCode',
      params,
  })
}

// 短信修改密码
export function resetPassword(data) {
  return request({
      method: 'POST',
      url: '/system/user/resetPassword',
      data,
  })
}

//个人信息
export function me(params) {
  return request({
      url: '/system/user/profile',
      method: 'GET',
      params,
  })
}

//登出
export function logout(data) {
  return request({
      url: '/auth/logout',
      method: 'DELETE',
      data,
  })
}

//消息通知
export function information(params) {
  return request({
      url: '/equipment/message_record/list',
      method: 'GET',
      params,
  })
}

//消息通知
export function getMyUnreadMessages(params) {
  return request({
      url: '/equipment/message_record/getMyUnreadMessages',
      method: 'GET',
      params,
  })
}

// 用户头像上传
export function uploadAvatar(data) {
  return request({
    url: '/system/user/profile/avatar',
    method: 'post',
    data: data
  })
}

//修改个人信息
export function doInfo(data) {
  return request({
      url: '/system/user/profile',
      method: 'PUT',
      data,
  })
}

//部门列表
export function deptInfo(params) {
  return request({
      url: '/system/dept/list',
      method: 'GET',
      params,
  })
}

//已读
export function readInfo(params) {
  return request({
      url: '/equipment/message_record/read',
      method: 'GET',
      params,
  })
}

//全部已读
export function readInfoAll(params) {
  return request({
      url: '/equipment/message_record/readAll',
      method: 'GET',
      params,
  })
}

//删除
export function deleteInfo(params) {
  return request({
      url: '/equipment/message_record/delete',
      method: 'DELETE',
      params,
  })
}

//全部删除
export function deleteInfoAll(params) {
  return request({
      url: '/equipment/message_record/deleteAll',
      method: 'DELETE',
      params,
  })
}
