<template>
  <div id="app">
    <audio ref="audio" class="aud">
      <source src="https://kyfw.12306.cn/otn/resources/js/framework/audio/message.wav" />
    </audio>
    <infoDialog ref="infoDialog" :type="1" title="请立即关闭消防泵，存在以下风险告警:" />
    <router-view />
  </div>
</template>

<script>
import { getAlarmConfigSet } from "@/api/common";
import infoDialog from "@/components/infoDialog";
import setting from "@/config/setting";

export default {
  components: { infoDialog },
  data() {
    return {
      path: `${window.location.protocol.includes('https') ? 'wss' : 'ws'}://ws.anmei.xinzhidi.cn/websocket/alarm_record`,
      socket: null,
      form: {},
      heartbeatInterval: 30000, // 心跳间隔，单位为毫秒
      heartbeatTimer: null, // 心跳定时器
    };
  },
  mounted() {
    if (setting.takeToken()) {
      this.init();
    } else {
      // 处理未登录的情况
    }
  },
  computed: {
    loginUser() {
      return this.$store.state.user.user;
    },
  },
  watch: {
    loginUser(e) {
      if (e.userId) this.init();
    },
  },
  methods: {
    init() {
      console.log('init ??');

      if (typeof WebSocket === 'undefined') {
        alert('您的浏览器不支持socket');
      } else {
        this.socket = new WebSocket(`${this.path}_${this.loginUser.topDeptId}/${this.loginUser.userId}_pc`);
        this.socket.onopen = this.open;
        this.socket.onerror = this.error;
        this.socket.onmessage = this.getMessage;
        this.socket.onclose = this.close;
        // 启动心跳定时器
        this.startHeartbeat();
      }

      getAlarmConfigSet()
        .then(({ data }) => {
          this.form = JSON.parse(data);
          console.log(this.form, 'form1213');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    startHeartbeat() {
      this.heartbeatTimer = setInterval(() => {
        if (this.socket.readyState === WebSocket.OPEN) {
          this.socket.send('ping'); // 发送心跳消息
        }
      }, this.heartbeatInterval);
    },
    stopHeartbeat() {
      clearInterval(this.heartbeatTimer);
    },
    getMessage(res) {
      let result = res.data != '来自后台的反馈：连接成功' ? JSON.parse(res.data) : '';
      console.log(result, 'result');
      if (!result) {
        return;
      }

      setTimeout(() => {
        this.$nextTick(() => {
          let music1 = new Audio();
          music1.src =
            result.alarmLevel == 0
              ? this.form.sameSoundConfig
              : result.alarmLevel == 1
              ? this.form.imSoundConfig
              : result.alarmLevel == 2
              ? this.form.urgentSoundConfig
              : 'https://kyfw.12306.cn/otn/resources/js/framework/audio/message.wav';
          this.$refs.audio = music1;
          console.log(this.$refs.audio, 'this.$refs.audio');
          this.$refs.audio.play();

          if (result.reminderMethod != 2) {
            return;
          }
          this.$refs.infoDialog.start(result);
        });
      }, 800);
    },
    open() {
      console.log('socket连接成功');
    },
    error() {
      console.log('连接错误');
    },
    close(event) {
      console.log('socket已经关闭');
      // 关闭连接时停止心跳定时器
      this.stopHeartbeat();
      // 打印关闭事件的相关信息
      console.error('WebSocket 关闭事件:', event);
      if (event.code === 1006) {
        console.log('尝试重新连接...');
        this.init();
      }
    },
    send(params) {
      this.socket.send(params);
    },
  },
};
</script>

<style lang="scss">
[v-cloak] 
{ 
display:none;
}
* {
  padding: 0;
  margin: 0;
  font-size: 13px;
}

// ::-webkit-scrollbar {
//   width: 0 !important;
// }
// ::-webkit-scrollbar {
//   width: 0 !important;height: 0;
// }
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(147, 147, 153, 0.5);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

.el-message--error{
  background-color: #FFF !important;
  border-color: #FFF !important;
}
.el-message--error .el-message__content{
  color: #333 !important;
}

 //显示时按钮样式 
 .el-button--danger { //需要更改的按钮类型 type='danger'
  background: #bb0100 !important;
  border-color: #bb0100 !important;
  color: #FFF !important;
}
//移入时按钮样式 type='danger'
.el-button--danger:hover {
  background: #bb0100 !important;
  border-color: #bb0100 !important;
  color: #FFF !important;
  opacity: 0.8;
}

 //显示时按钮样式 
 .el-button--success { //需要更改的按钮类型 type='success'
  background: #38c25d !important;
  border-color: #38c25d !important;
  color: #FFF !important;
}
//移入时按钮样式 type='success'
.el-button--success:hover {
  background: #38c25d !important;
  border-color: #38c25d !important;
  color: #FFF !important;
  opacity: 0.8;
}

 //显示时按钮样式 
 .el-button--warning { //需要更改的按钮类型 type='success'
  background: #ef9d11 !important;
  border-color: #ef9d11 !important;
  color: #FFF !important;
}
//移入时按钮样式 type='success'
.el-button--warning:hover {
  background: #ef9d11 !important;
  border-color: #ef9d11 !important;
  color: #FFF !important;
  opacity: 0.8;
}
</style>
