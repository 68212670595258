import request from "@/config/request";

//泵房列表
export function pumpRoom(params) {
    return request({
        url: '/equipment/app/homePage/pumpRoomList',
        method: 'GET',
        params,
    })
}

//泵房健康状态
export function healthStatusDetails(params) {
    return request({
        url: '/equipment/app/pumpRoom/healthStatusDetails',
        method: 'GET',
        params,
    })
}

//泵房环境数据
export function pumpRoomEnvironmentData(params) {
    return request({
        url: '/equipment/app/homePage/pumpRoomEnvironmentData',
        method: 'GET',
        params,
    })
}

//首页-设备统计
export function deviceStatistical(params) {
    return request({
        url: '/equipment/pc/homePage/deviceStatistical',
        method: 'GET',
        params,
    })
}

//首页-实时告警、事件
export function realTimeAlarmOrEvent(params) {
    return request({
        url: '/equipment/pc/homePage/realTimeAlarmOrEvent',
        method: 'GET',
        params,
    })
}

//首页-告警统计
export function alarmStatistical(params) {
    return request({
        url: '/equipment/pc/homePage/alarmStatistical',
        method: 'GET',
        params,
    })
}

//首页-巡检进度
export function inspectionProgressVo(params) {
    return request({
        url: '/equipment/pc/homePage/inspectionProgressVo',
        method: 'GET',
        params,
    })
}

//首页-mapCoordinate
export function mapCoordinate(params) {
    return request({
        url: '/equipment/app/pumpRoom/mapCoordinate',
        method: 'GET',
        params,
    })
}

// 详情
export function details(id) {
    return request({
        url:'/equipment/ginseng_information/' + id,
        method: 'GET'
    })
}