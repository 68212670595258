<!--  -->
<template>
    <el-dialog v-dialogDrag :title="title" :visible.sync="open" width="1000px" append-to-body :before-close="cancel">
      <div v-loading="loading">
        <el-form ref="form" :model="form" size="mini"  disabled :rules="rules" label-width="auto">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item :label="$t('i18n_4')" prop="title">
                <el-input v-model="form.title" :placeholder="$t('i18n_5')"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_6')" prop="companyName">
                <el-input v-model="form.companyName" :placeholder="$t('i18n_7')"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_8')" prop="theBuilding">
                <el-input v-model="form.theBuilding" :placeholder="$t('i18n_9')"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_10')" prop="pumpRoomName">
                <el-input v-model="form.pumpRoomName" :placeholder="$t('i18n_11')"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_12')" prop="location">
                <div class="d-flex">
                  <el-input class="flex-fill me-2" v-model="form.location" readonly :placeholder="$t('i18n_13')"/>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_14')" prop="areaCode">
                <el-input v-model="form.areaCode" readonly :placeholder="$t('i18n_15')"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_16')" prop="streetCode">
                <el-input v-model="form.streetCode" readonly :placeholder="$t('i18n_17')"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_18')" prop="roadName">
                <el-input v-model="form.roadName" readonly :placeholder="$t('i18n_19')"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_20')" prop="longitude">
                <el-input v-model="form.longitude" readonly :placeholder="$t('i18n_21')" type="number" class="number-input"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_22')" prop="latitude">
                <el-input v-model="form.latitude" readonly :placeholder="$t('i18n_23')" type="number" class="number-input"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_24')" prop="fireUnit">
                <el-input v-model="form.fireUnit" :placeholder="$t('i18n_25')"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_26')" prop="currentUnit">
                <el-input v-model="form.currentUnit" :placeholder="$t('i18n_27')"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_28')" prop="principal">
                <el-input v-model="form.principal" :placeholder="$t('i18n_29')"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_30')" prop="contactPerson">
                <el-input v-model="form.contactPerson" :placeholder="$t('i18n_31')"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_32')" prop="contactNumber">
                <el-input v-model="form.contactNumber" :placeholder="$t('i18n_33')" type="number" class="number-input"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_34')" prop="buildingCondition">
                <el-select v-model="form.buildingCondition" :placeholder="$t('i18n_35')" class="w-100">
                  <el-option :label="$t('i18n_36')" :value="0"></el-option>
                  <el-option :label="$t('i18n_37')" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_38')" prop="buildingPropertyRights">
                <el-select v-model="form.buildingPropertyRights" :placeholder="$t('i18n_39')" class="w-100">
                  <el-option v-for="(item,index) in buildingPropertyRightsList" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_40')" prop="level">
                <el-select v-model="form.level" :placeholder="$t('i18n_41')" class="w-100">
                  <el-option v-for="dict in fire_rating" :key="dict.value" :label="dict.label" :value="dict.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_42')" prop="registerDate">
                <el-date-picker value-format="yyyy-MM-dd" v-model="form.registerDate" type="date" :placeholder="$t('i18n_43')" class="w-100"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_44')" prop="owner">
                <el-input v-model="form.owner" :placeholder="$t('i18n_45')"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_46')" prop="ownerLocation">
                <el-input v-model="form.ownerLocation" :placeholder="$t('i18n_47')"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_48')" prop="ownerPrincipal">
                <el-input v-model="form.ownerPrincipal" :placeholder="$t('i18n_49')"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_50')" prop="agreement">
                <el-input v-model="form.agreement" :placeholder="$t('i18n_51')"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_52')" prop="ownerContactNumber">
                <el-input v-model="form.ownerContactNumber" :placeholder="$t('i18n_53')" type="number" class="number-input"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_54')" prop="contractor">
                <el-input v-model="form.contractor" :placeholder="$t('i18n_55')"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('i18n_56')" prop="itemNo">
                <el-input v-model="form.itemNo" :placeholder="$t('i18n_57')"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <ChooseOnMap ref="map" @change="handleAddressChange"/>
      <!-- <div slot="footer" class="dialog-footer" v-if="type!=1">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm" :loading="loading">确 定</el-button>
      </div> -->
    </el-dialog>
  </template>
  <script>
  import {details} from "@/api/home";
//   import ChooseOnMap from "@/components/ChooseOnMap";
  
  export default {
    // components: {ChooseOnMap},
    // dict: ["fire_rating"],
    data() {
      return {
        title: this.$t("i18n_58"),
        open: false,
        buildingPropertyRightsList: [
          {
            label: this.$t("i18n_59"),
            value: 0,
          },
          {
            label: this.$t("i18n_60"),
            value: 1,
          },
          {
            label: this.$t("i18n_61"),
            value: 2,
          },
        ],
        form: {
          title: "",
          fireUnit: "",
          status: 1,
          currentUnit: "",
          principal: "",
          contactPerson: "",
          contactNumber: "",
          buildingCondition: "",
          level: "",
          registerDate: "",
          buildingPropertyRights: "",
          companyName: "",
          theBuilding: "",
          pumpRoomName: "",
          location: "",
          areaCode: "",
          streetCode: "",
          roadName: "",
          longitude: "",
          latitude: "",
          owner: "",
          ownerPrincipal: "",
          ownerContactNumber: "",
          contractor: "",
          itemNo: "",
        },
        rules: {
          itemNo: [{required: true, trigger: "blur", message: "请输入项目编号"}],
          contractor: [{required: true, trigger: "blur", message: "请输入承包商名称"}],
          ownerContactNumber: [{required: true, trigger: "blur", message: "请输入业主电话号码"}],
          agreement: [{required: true, trigger: "blur", message: "请输入合同编号"}],
          owner: [{required: true, trigger: "blur", message: "请输入业主名称"}],
          ownerLocation: [{required: true, trigger: "blur", message: "请输入业主地址"}],
          ownerPrincipal: [{required: true, trigger: "blur", message: "请输入业主方负责人"}],
          fireUnit: [{required: true, trigger: "blur", message: "请输入消防主管单位"}],
          currentUnit: [{required: true, trigger: "blur", message: "请输入所在单位"}],
          principal: [{required: true, trigger: "blur", message: "请输入责任人"}],
          contactPerson: [{required: true, trigger: "blur", message: "请输入联系人"}],
          contactNumber: [{required: true, trigger: "blur", message: "请输入联系电话"}],
          buildingCondition: [{required: true, trigger: "change", message: "请选择建筑情况"}],
          level: [{required: true, trigger: "change", message: "请选择耐火等级"}],
          registerDate: [{required: true, trigger: "change", message: "请选择注册日期建造日期"}],
          buildingPropertyRights: [{required: true, trigger: "change", message: "请选择建筑产权及使用情况"}],
          title: [{required: true, trigger: "blur", message: "请输入工参名称"}],
          companyName: [{required: true, trigger: "blur", message: "请输入公司名"}],
          theBuilding: [{required: true, trigger: "blur", message: "请输入所在建筑"}],
          pumpRoomName: [{required: true, trigger: "blur", message: "请输入泵房名称"}],
          location: [{required: true, trigger: "change", message: "请输入位置和地址"}],
          // areaCode: [{required: true, trigger: "blur", message: "请输入区域编码"}],
          // streetCode: [{required: true, trigger: "blur", message: "请输入街道编码"}],
          // roadName: [{required: true, trigger: "blur", message: "请输入路名"}],
          // longitude: [{required: true, trigger: "blur", message: "请输入经度"}],
          // latitude: [{required: true, trigger: "blur", message: "请输入纬度"}],
        },
        loading: false,
        type: "",
        fire_rating:[{value:1,label:this.$t('i18n_62')},{value:2,label:this.$t('i18n_63')}]
      };
    },
    methods: {
      start(val = {}, type = null) {
        this.type = type;
        this.title = this.$t("i18n_64");
        if (val.id) {
          this.title = this.$t("i18n_65");
          this.loading = true;
          details(val.ginsengInformationId).then(({data}) => {
            data.level = data.level.toString();
            this.form = data;
          }).finally(() => {
            this.loading = false;
          });
        }
        this.open = true;
        
      },
      handleAddressChange(addressInfo) {
        this.form.location = addressInfo.address
        this.form.areaCode = addressInfo.regionCode
        this.form.streetCode = addressInfo.streetNumber
        this.form.roadName = addressInfo.streetName
        this.form.latitude = addressInfo.lat
        this.form.longitude = addressInfo.lng
      },
      submitForm() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.loading = true;
            let api = add;
            if (this.form.id) {
              api = update;
            }
            api(this.form).then((res) => {
              this.$modal.msgSuccess(res.msg);
              this.$emit("reload");
              this.cancel();
            }).finally(() => {
              this.loading = false;
            });
          }
        });
      },
      cancel() {
        this.$refs.form.resetFields();
        this.form.id = "";
        this.open = false;
      },
    },
  };
  </script>
  <style scoped lang="scss">
  ::v-deep .number-input input::-webkit-outer-spin-button,
  ::v-deep .number-input input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  
  ::v-deep .number-input input[type="number"] {
    -moz-appearance: textfield !important;
  }
  </style>
  